/* eslint-disable react/display-name */
import React from 'react';

// import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import {
  QuestionCircleOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Modal, Tooltip } from 'antd';
import moment from 'moment-timezone';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { BounceLoader } from 'react-spinners';
import { v4 as uuidv4 } from 'uuid';

import { DeleteIcon, Table } from '../components';
import { esSearchInstitution, getUserPortFolio } from '../graphql';
import { ALERT_TYPES } from '../views/Alerts/Alerts.Constants';
import styles from '../views/searchReport/style.module.scss';
import { red, green, black1, white, yellow } from './color';
import {
  formatNumber,
  formatNumber2,
  formatNumberWithPercent,
  currencyFormat,
  currencyFormat2,
  currencyFormat3,
  sharesCurrencyFormat,
  getLastQtrByIdx,
  getCurrentSECDateRange,
  // currencyFormat4,
} from './helpers';
import { ColumnWidths } from './TableColumnWidth';

// const dispatch = useDispatch();
// const { theme } = useTheme();
// const theme = 'light';

export const rowTitle = (value: string) => {
  // const parts = value.split(' ');
  // const newValue = parts.length >=3 ?  : value
  return (
    <div
      style={{
        fontSize: 11,
        fontWeight: 700,
        textAlign: 'center',
        wordBreak: 'break-word',
        color: yellow,
      }}
    >
      {value}
    </div>
  );
};

export const rowCurrentPriceTitle = (value: string, Source) => {
  // const parts = value.split(' ');
  // const newValue = parts.length >=3 ?  : value
  return (
    <div
      style={{
        fontSize: 11,
        fontWeight: 700,
        textAlign: 'center',
        wordBreak: 'break-word',
        color: yellow,
      }}
    >
      {value === 'Last Sale' ? 'Current Price' : value}
    </div>
  );
};

export const rowTitleWithToolTip = (
  note: string,
  toolTipText: string,
  value: string,
  type?: string,
  note2?: string,
) => {
  return (
    <div
      style={{
        fontSize: 12,
        fontWeight: 700,
        textAlign: 'center',
      }}
    >
      {rowTitle(value)}
      <Tooltip
        placement="top"
        title={
          note2 ? (
            <>
              {note2}
              <br />
              <br />
              {note}
            </>
          ) : (
            note
          )
        }
        color={green}
        overlayStyle={{
          color: black1,
          width: type === 'IAR' ? 250 : type === 'SR' ? 300 : 220,
        }}
      >
        <span style={{ cursor: 'pointer', fontWeight: 'bold' }}>
          {toolTipText}
        </span>
      </Tooltip>
    </div>
  );
};

const getColor = (value: number, theme: string) => {
  return value > 0
    ? green
    : value < 0
    ? red
    : theme === 'dark'
    ? white
    : black1;
};
const getCIK = async (text: string) => {
  const res = await esSearchInstitution(text);
  if (res) {
    return res[0].cik;
  } else {
    return null;
  }
};

const getOrder = (sortDetail = [], key = '') => {
  let order = false;
  let index = (sortDetail || []).findIndex((d) => d.sortBy === key);
  if (index > -1) {
    order =
      sortDetail[index].sortOrder === 'ASC'
        ? 'ascend'
        : sortDetail[index].sortOrder === 'DESC'
        ? 'descend'
        : false;
  }
  return order;
};

const getSec13CountTooltip = (note) => {
  const date = getCurrentSECDateRange();
  return (
    <span>
      {`Transactions From ${
        note === 'SDL'
          ? moment().subtract(1, 'years').format('DD MMM YYYY')
          : moment(date.start).format('DD MMM YYYY')
      }`}{' '}
      <br /> {`to ${moment().format('DD MMM YYYY')}`}{' '}
    </span>
  );
};

// const getlastQtr1ApproxInvestedValueToopTip = (text) => {
//   return (
//     <span>
//       {`${text}`}
//       <br />
//       {`* N/A for 'NO CHANGE' Status`}
//     </span>
//   );
// };

const getlastQtr1ApproxInvestedValueTitle = (value) => {
  const title = value.split('tooltip');
  return rowTitleWithToolTip(
    <span>
      {`${title[1]}`}
      <br />
      {`* N/A for 'NO CHANGE' Status`}
    </span>,
    <InfoCircleOutlined />,
    title[0],
  );
};

const getlastQtr1ApproxInvestedValueTitle2 = (value) => {
  const title = value.split('tooltip');
  return rowTitleWithToolTip(
    <span>{`${title[1]}`}</span>,
    <InfoCircleOutlined />,
    title[0],
  );
};
const getTrendinngStocKVoulmeColor = () => {
  var tmz = 'America/New_York';
  var fmt = 'HH:mm:ss';
  var start = moment.tz('04:00:00', fmt, tmz);
  var end = moment.tz('09:30:00', fmt, tmz);
  const isBetween04And0930 = moment
    .tz(moment.now(), 'America/New_York')
    .isBetween(start, end);

  return isBetween04And0930 && moment().isSame(new Date(), 'day');
};
const sec13dNewCountTitle = (value) => {
  const [p1, p2] = value.split('13D/G');
  return (
    <span>
      {`${p1}13D/G`} <br /> {p2}
    </span>
  );
};

const getColorForStatus = (status, theme) => {
  let color = theme === 'dark' ? white : black1;
  if (status) {
    if (status.toUpperCase().search('INCREASED') > -1) color = green;
    if (status.toUpperCase().search('UP') > -1) color = green;
    if (status.toUpperCase().search('REDUCED') > -1) color = red;
    if (status.toUpperCase().search('DOWN') > -1) color = red;
    if (status.toUpperCase().search('DECREASED') > -1) color = red;
    if (
      status.toUpperCase().search('NEWLY BOUGHT') > -1 ||
      status.toUpperCase().search('NEWLY_BOUGHT') > -1
    )
      color = green;
    if (
      status.toUpperCase().search('SOLD OFF') > -1 ||
      status.toUpperCase().search('SOLD_OFF') > -1
    )
      color = red;
  }
  return color;
};

const formatStatus = (
  status: string,
  source: string,
  record: { type: string },
) => {
  if (source === 'alert' && record.type === 'INSIDER') {
    if (status === 'INCREASED') {
      return 'BOUGHT';
    } else if (status === 'DECREASED') {
      return 'SOLD';
    }
  } else if (status === 'DECREASED') {
    return 'REDUCED';
  } else if (status === 'NEWLY_BOUGHT') {
    return 'NEWLY BOUGHT';
  } else if (status === 'SOLD_OFF') {
    return 'SOLD OFF';
  } else if (status === 'NO_CHANGE') {
    return 'NO CHANGE';
  }

  return status;
};

function formatInstitutionsText(
  text: any,
  source: string | undefined,
  record: any,
) {
  if (text && text.length) {
    if (source === 'alert') {
      return text.map((i: any) => (
        <p style={{ margin: 0 }} key={i}>
          {i}
        </p>
      ));
    } else {
      return text.join(',   ');
    }
  } else {
    if (source === 'alert') {
      if (record.type === ALERT_TYPES.INSIDER) {
        return '-';
      } else {
        return 'All';
      }
    } else {
      return '';
    }
  }
}
function formatStatusText(text: any, source: string | undefined, record: any) {
  if (text && text.length) {
    if (source === 'alert') {
      return text.map((i: any) => (
        <p style={{ margin: 0 }} key={i}>
          {formatStatus(i, source, record)}
        </p>
      ));
    } else {
      return text.join(',   ');
    }
  } else {
    if (source === 'alert') {
      if (record.type === ALERT_TYPES.INSIDER) {
        return '-';
      } else {
        return 'All';
      }
    } else {
      return '';
    }
  }
}

const camel2title = (camelCase) =>
  camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();

const getSorterValue = (key) => {
  let currentWeekno = moment.tz('America/New_York').week();
  if (moment().tz('America/New_York').day() === 0) {
    currentWeekno = currentWeekno - 1;
  }
  return key === `week_${currentWeekno}` ? true : true;
};

function getDisplayableQtr() {
  const qtrString = getLastQtrByIdx(1).split('_');
  return qtrString[1] + '_' + qtrString[0];
}
function getDisplayablePrevQtr() {
  const qtrString = getLastQtrByIdx(2).split('_');
  return qtrString[1] + '_' + qtrString[0];
}

export const formatColumn = (
  tableColumns,
  sort = false,
  sortDetail?,
  history?,
  showDetail?: boolean,
  note?: string,
  source?: string,
  onDelete?: () => {},
  onEdit?: () => {},
  theme,
  handleISRTOIIR?: Function,
  isStatusSort?: boolean,
  filterOptions,
  selectedQuarter,
  handleISRTOStatusReport?: Function,
  multiStatus,
) =>
  tableColumns
    .sort((a, b) => a.index - b.index)
    .map((col) => {
      const { key, title, value } = col;
      let baseProperties = {
        title:
          source === 'MMS' && (title === 'Symbol' || value === 'Symbol')
            ? rowTitle('Stock')
            : rowTitle(title || value),
        dataIndex: key,
        width: 80,
        key: `${key}-${uuidv4()}`,
        align: 'center',
        sorter: sort,
        sortDirections: ['descend', 'ascend'],
        // showSorterTooltip: false,
        sortOrder: getOrder(sortDetail, key),
      };
      let baseStyling = {
        textAlign: 'center',
        fontSize: 10,
        margin: 'auto',
        userSelect: 'none',
        // 'word-break': 'break-all',
        // idth: ColumnWidths[key],
      };
      const destroyAll = () => {
        Modal.destroyAll();
      };
      const stocksModal = (text, record) => {
        const payloadForPortfolio = {
          type: 'virtual',
          name: '',
          email: record.email,
        };
        console.log(record);
        let formattedColumns = {};
        let userPortfolio = {};
        getUserPortFolio(payloadForPortfolio).then((res) => {
          userPortfolio = res;
          formattedColumns = Object.keys({
            symbol: 'Symbol',
            no_of_shares: 'Quantity',
            lastSale: 'Last Sale',
            avg_cost: 'Avg Cost',
            totalReturns: 'Total Returns $',
            totalReturnsPerc: 'Total Returns %',
            name: 'Company',
          }).map((col, index) => ({
            key: col,
            name: col,
            value: res.columns[col],
            visible: true,
            index: index,
          }));
          destroyAll();
          Modal.info({
            closable: true,
            title: `Portfolio Snapshot of ${text}`,
            width: '800px',

            content: (
              <p style={{ fontSize: '1.2rem' }}>
                <div
                  style={{ width: '100%', margin: 'auto', marginTop: '30px' }}
                >
                  <Table
                    scroll={{ x: true }}
                    loading={false}
                    columns={formatColumn(
                      formattedColumns,
                      false,
                      sortDetail,
                      history,
                      false,
                      null,
                      'gamification',
                      null,
                      null,
                      theme,
                    )}
                    dataSource={userPortfolio.data}
                    pagination={false}
                    size="small"
                    bordered={true}
                    id="allPicksId"
                  />
                </div>
              </p>
            ),
          });
        });
        console.log(userPortfolio);
        Modal.info({
          closable: true,
          title: `Portfolio Snapshot of ${text}`,
          width: '800px',

          content: (
            <p style={{ fontSize: '1.2rem' }}>
              <div style={{ width: '100%', margin: 'auto', marginTop: '30px' }}>
                <div className={styles.miniLoaderStyle}>
                  <BounceLoader
                    color={green}
                    loading={!userPortfolio.data}
                    size={50}
                  />
                </div>
              </div>
            </p>
          ),
        });
      };
      switch (key) {
        case 'rank':
          return {
            ...baseProperties,
            title: rowTitleWithToolTip(
              source === 'isr'
                ? `Rank is based on the Highest ${
                    filterOptions.status === 'INACTIVE'
                      ? 'Negative'
                      : 'Positive'
                  }  Returns%`
                : 'Rank is based on Market Value',
              <QuestionCircleOutlined />,
              value,
            ),
            width: (source === 'isr' ? 40 : ColumnWidths[key]) || 80,
            fixed: 'left',
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling }}
              >
                {text}
              </p>
            ),
          };
        case 'symbol':
          return {
            ...baseProperties,
            fixed: 'left',
            width: source === 'MMS' ? 60 : ColumnWidths[key],
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  color: green,
                  fontWeight: 700,
                  cursor:
                    text === 'Total' || !history || source === 'gamification'
                      ? 'auto'
                      : 'Pointer',
                  textDecoration:
                    text === 'Total' || !history || source === 'gamification'
                      ? 'none'
                      : 'underline',
                  ...baseStyling,
                  fontSize: 10,
                }}
                onClick={() => {
                  let filters = {};
                  if (source === 'WEEKLY_GAINS_REPORT') {
                    filters = {
                      symbols: text,
                      type: 'INTERNAL_REPORTS',
                      subtype: 'WEEKLY_GAINS_REPORT',
                    };
                  } else if (source === 'gamification') {
                    return;
                  } else {
                    filters = {
                      type: 'SYMBOL'.toLowerCase(),
                      subtype: 'INDIVIDUAL_STATISTICAL_REPORT'.toLowerCase(),
                      symbols: text,
                    };
                  }
                  if (
                    history &&
                    text !== 'Total' &&
                    source !== 'WEEKLY_GAINS_REPORT'
                  ) {
                    history.push(
                      `/search/report/` + queryString.stringify(filters),
                    );
                  } else if (source === 'WEEKLY_GAINS_REPORT') {
                    history.push(
                      `/search/report/` + queryString.stringify(filters),
                    );
                    // window.location.reload();
                  }
                }}
              >
                {text}
              </p>
            ),
          };
        case 'newlyBought':
        case 'increased':
        case 'decreased':
        case 'noChange':
        case 'soldOff':
        case 'reduced':
        case 'total':
          return {
            ...baseProperties,
            title: rowTitleWithToolTip(
              selectedQuarter
                ? `
Percentage formula: Individual Stock ${camel2title(
                    key,
                  )} Institutional  Shares value/ All Stocks Total ${camel2title(
                    key,
                  )} Institutional  Shares value`
                : '',
              selectedQuarter ? <QuestionCircleOutlined /> : '',
              value,
              'SR',
              `Approx. Trxn value formula: Total ${camel2title(
                key,
              )} shares* Q3_23 Avg. price`,
            ),
            width: ColumnWidths[key],
            render: (text) => (
              <>
                <div
                  style={{
                    display: 'inline',
                  }}
                >
                  <span
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    style={
                      selectedQuarter
                        ? {
                            color: green,
                            fontWeight: 700,
                            cursor: 'Pointer',
                            marginLeft: 5,
                            marginTop: 8,
                            textAlign: 'center',
                            fontSize: 12,
                            userSelect: 'none',
                          }
                        : { fontSize: 12, fontWeight: 'lighter' }
                    }
                    onClick={() => {
                      let filters = {};
                      filters = {
                        type: 'SYMBOL'.toLowerCase(),
                        subtype: 'INDIVIDUAL_STATISTICAL_REPORT'.toLowerCase(),
                        symbols: text?.split(' ')[0],
                      };
                      if (history) {
                        history.push(
                          `/search/report/` + queryString.stringify(filters),
                        );
                      }
                    }}
                  >
                    {text?.split(' ')[0] || '-'}
                  </span>
                  <span
                    hidden={!selectedQuarter}
                    style={{
                      cursor: 'Pointer',
                      marginLeft: 5,
                      textAlign: 'center',
                      fontSize: 12,
                      userSelect: 'none',
                    }}
                  >
                    (
                    {currencyFormat(
                      text?.split(' ')[2],
                      false,
                      false,
                      2,
                      false,
                      true,
                    )}
                    )
                  </span>
                  <span
                    hidden={!selectedQuarter}
                    style={{
                      cursor: 'Pointer',
                      marginLeft: 5,
                      textAlign: 'center',
                      fontSize: 12,
                      userSelect: 'none',
                    }}
                  >
                    ({formatNumberWithPercent(text?.split(' ')[5])})
                  </span>
                </div>
              </>
            ),
          };
        case 'healthCare':
        case 'basicMaterials':
        case 'communicationServices':
        case 'consumerCyclical':
        case 'consumerDefensive':
        case 'energy':
        case 'financialServices':
        case 'industrials':
        case 'others':
        case 'realEstate':
        case 'technology':
        case 'utilities':
          return {
            ...baseProperties,
            width: ColumnWidths[key],
            title: rowTitleWithToolTip(
              <>
                <span style={{ marginLeft: 60 }}>Active Stocks</span>
                <br />
                <span style={{ marginLeft: 10 }}>
                  Apprx. Inv Amount (Allocated %)
                </span>
              </>,
              <InfoCircleOutlined />,
              value,
              'IAR',
            ),
            render: (text) => (
              <>
                <div
                  style={{
                    display: 'inline',
                  }}
                >
                  <span
                    style={{
                      cursor: 'Pointer',
                      marginLeft: 5,
                      textAlign: 'center',
                      fontSize: 12,
                      userSelect: 'none',
                    }}
                  >
                    {text === '-'
                      ? '-'
                      : formatNumber2(
                          text && text.length ? text.split(' ')[0] : 0,
                        )}
                  </span>
                  <br />
                  <span
                    hidden={text === '-'}
                    style={{
                      cursor: 'Pointer',
                      textAlign: 'center',
                      fontSize: 12,
                      userSelect: 'none',
                    }}
                  >
                    {currencyFormat(
                      text && text.length ? text.split(' ')[5] : 0,
                      false,
                      false,
                      2,
                      false,
                      true,
                    )}
                  </span>
                  <span
                    hidden={text === '-'}
                    style={{
                      cursor: 'Pointer',
                      marginLeft: 5,
                      textAlign: 'center',
                      fontSize: 12,
                      userSelect: 'none',
                    }}
                  >
                    ({' '}
                    {formatNumberWithPercent(
                      text && text.length ? text.split(' ')[2] : 0,
                    )}
                    )
                  </span>
                </div>
              </>
            ),
          };
        case 'totalStocks':
          return {
            ...baseProperties,
            width: ColumnWidths[key],
            title: rowTitleWithToolTip(
              'Total Stocks Excluding sold Off and Warrants',
              <InfoCircleOutlined />,
              value,
            ),
            render: (text) => (
              <>
                <div
                  style={{
                    display: 'inline',
                  }}
                >
                  <span
                    style={{
                      cursor: 'Pointer',
                      marginLeft: 5,
                      textAlign: 'center',
                      fontSize: 12,
                      userSelect: 'none',
                    }}
                  >
                    {formatNumber2(text)}
                  </span>
                </div>
              </>
            ),
          };
        case 'growthSinceRecommended':
        case 'highestGrowthSinceRecommended':
        case 'outstandingSharesPer':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  color: text < 0 ? red : green,
                  // width: getWidth(text, ColumnWidths[key]),
                  ...baseStyling,
                  fontSize: 12,
                }}
              >
                {formatNumberWithPercent(text)}
              </p>
            ),
          };
        case 'volDiff':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            title: rowTitleWithToolTip(
              `((Current VOL - Previous VOL) * 100 / Previous VOL)`,
              <QuestionCircleOutlined />,
              value,
            ),
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  color: text < 0 ? red : green,
                  // width: getWidth(text, ColumnWidths[key]),
                  ...baseStyling,
                  fontSize: 12,
                }}
              >
                {text && text !== '0' ? `${formatNumber(text)} %` : '-'}
              </p>
            ),
          };
        case 'approxInvestedValue':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            title: rowTitleWithToolTip(
              note === 'SAV'
                ? `${
                    filterOptions.status === 'DECREASED' ? 'Sold ' : 'Bought '
                  } Shares Diff * 10 Days Avg Price`
                : note === 'countStatus'
                ? `Shares Diff * ${getDisplayableQtr()} Avg Price`
                : `Shares Diff * 10 Days Avg Price`,
              <QuestionCircleOutlined />,
              value,
            ),
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  color: text < 0 ? red : green,
                  // width: getWidth(text, ColumnWidths[key]),
                  ...baseStyling,
                  fontSize: 12,
                }}
              >
                {currencyFormat(text, false, false, 2, false, true)}
              </p>
            ),
          };
        case 'shares_value':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            title: rowTitleWithToolTip(
              `(Shares * Report Date Price)`,
              <QuestionCircleOutlined />,
              value,
            ),
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, fontSize: 12 }}
              >
                {sharesCurrencyFormat(text, false, false, 2)}
              </p>
            ),
          };
        case 'reason':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling }}
              >
                {text}
              </p>
            ),
          };
        case 'rsiDate':
          return {
            ...baseProperties,
            title: rowTitleWithToolTip(
              `MM criteria met Date`,
              <QuestionCircleOutlined />,
              value,
            ),
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, fontSize: 12 }}
              >
                {text ? moment(text).format('DD MMM YYYY') : '-'}
              </p>
            ),
          };
        case 'date':
        case 'sale_date':
        case 'highestPriceDate':
        case 'created_at':
        case 'last_modified_at':
        case 'dateReported':
        case 'transactionTime':
        case 'entryDate':
        case 'event_date':
        case 'cnst_start_date':
        case 'cnst_end_date':
        case 'start_date':
        case 'end_date':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling }}
              >
                {text ? moment(text).format('DD MMM YYYY') : '-'}
              </p>
            ),
          };
        case 'insiderSecDate':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling }}
              >
                {moment(text).utc().format('DD MMM YYYY')}
                <br />
                {source !== 'INSIDER_ALL_CUMULATIVE_REPORT'
                  ? moment(text).utc().format('hh:mm A')
                  : ''}
              </p>
            ),
          };
        case 'secDate':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling }}
              >
                {moment(text).format('DD MMM YYYY')}
                <br />
                {moment(text).format('hh:mm A')}
              </p>
            ),
          };
        case 'detail':
          return {
            ...baseProperties,
            // fixed: 'right',
            width: ColumnWidths[key] || 80,
            title: rowTitle(value),
            sorter: false,
            render: (text, record) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onClick={() => showDetail(record)}
                style={{
                  ...baseStyling,
                  color: green,
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
              >
                View Detail
              </p>
            ),
          };
        case 'note':
          return {
            ...baseProperties,
            // fixed: 'right',
            width: ColumnWidths[key] || 80,
            title: rowTitle(value),
            sorter: false,
            render: (text, record) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onClick={() => showDetail(record)}
                style={{
                  ...baseStyling,
                  color: green,
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
              >
                View
              </p>
            ),
          };
        case 'symbols':
          return {
            ...baseProperties,
            fixed: 'left',
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  color: green,
                  fontWeight: 700,
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  fontSize: 12,
                  ...baseStyling,
                }}
                onClick={() => {
                  let filters = {};
                  if (text.length === 1) {
                    filters = {
                      type: 'SYMBOL'.toLowerCase(),
                      subtype: 'INDIVIDUAL_STATISTICAL_REPORT'.toLowerCase(),
                      symbols: text[0],
                    };
                  }
                  if (text.length > 1) {
                    filters = {
                      type: 'SYMBOL'.toLowerCase(),
                      subtype: 'COMPARE_SYMBOLS'.toLowerCase(),
                      symbols: text,
                    };
                  }
                  if (history) {
                    history.push(
                      `/search/report/` + queryString.stringify(filters),
                    );
                  }
                }}
              >
                {text ? text.join(',   ') : ''}
              </p>
            ),
          };
        case 'institutions':
          return {
            ...baseProperties,
            fixed: 'left',
            width: source === 'alert' ? 250 : ColumnWidths[key] || 80,
            render: (text, record) => (
              <p
                // onCopy={(e) => {
                //   e.preventDefault();
                //   return false;
                // }}
                onCopy={() => navigator.clipboard.writeText(text)}
                style={{
                  color: green,
                  // fontWeight: 700,
                  cursor: source === 'alert' ? 'auto' : 'pointer',
                  // textDecoration: 'underline',
                  fontSize: 12,
                  ...baseStyling,
                  userSelect: 'text',
                }}
                // onClick={() => {
                //   let filters = {};
                //   if (text.length === 1) {
                //     filters = {
                //       type: 'SYMBOL'.toLowerCase(),
                //       subtype: 'INDIVIDUAL_STATISTICAL_REPORT'.toLowerCase(),
                //       symbols: text[0],
                //     };
                //   }
                //   if (text.length > 1) {
                //     filters = {
                //       type: 'SYMBOL'.toLowerCase(),
                //       subtype: 'COMPARE_SYMBOLS'.toLowerCase(),
                //       symbols: text,
                //     };
                //   }
                //   if (history) {
                //     history.push(
                //       `/search/report/` + queryString.stringify(filters),
                //     );
                //   }

                // }}
              >
                {formatInstitutionsText(text, source, record)}
              </p>
            ),
          };
        case 'sharesChange':
        case 'sharesDiff':
        case 'totalSharesDiff':
        case 'eps_diff':
        case 'diff':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            sorter: false,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  color: getColor(text, theme),
                  ...baseStyling,
                  fontSize: 12,
                }}
              >
                {formatNumber2(text)}
              </p>
            ),
          };
        case 'sector':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  ...baseStyling,
                  wordBreak: 'break-all',
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
              >
                {/* <Tag
                    color={findSectorColor(text)}
                    key={text}
                    style={{
                      fontSize: 10,
                      margin: 0,
                    }}
                  >
                    {text && text.toUpperCase()}
                  </Tag> */}
                {text ? text.toUpperCase() : 'OTHERS'}
              </span>
            ),
          };
        case 'industry':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  ...baseStyling,
                  wordBreak: 'break-all',
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
              >
                {text ? text.toUpperCase() : '-'}
              </span>
            ),
          };
        case 'consistentlyIncrLastNQtrs':
        case 'consistentlyIncrLast2Qtrs':
          return {
            ...baseProperties,
            // sorter: false,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  ...baseStyling,
                  wordBreak: 'break-all',
                }}
              >
                {text ? text.toUpperCase() : '-'}
              </span>
            ),
          };
        case 'secForm':
          return {
            ...baseProperties,
            sorter: false,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <a
                style={{
                  textDecoration: 'underline',
                  fontSize: 10,
                  color: green,
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                href={text}
                rel="noopener noreferrer"
                target="_blank"
              >
                WebLink
              </a>
            ),
          };
        case 'secInstCount':
        case 'currentShares':
        case 'prevShares':
        case 'lastQtr1Count':
        case 'lastQtr2Count':
        case 'lastQtr3Count':
        case 'lastQtr4Count':
        case 'lastQtr5Count':
        case 'lastQtr6Count':
        case 'lastQtr7Count':
        case 'lastQtr8Count':
        case 'lastQtr1Shares':
        case 'lastQtr2Shares':
        case 'lastQtr3Shares':
        case 'lastQtr4Shares':
        case 'lastQtr5Shares':
        case 'lastQtr6Shares':
        case 'lastQtr7Shares':
        case 'lastQtr8Shares':
        case 'lastQtr1HedgeShares':
        case 'lastQtr1HedgeCount':
        case 'lastQtr2HedgeCount':
        case 'lastQtr3HedgeCount':
        case 'lastQtr4HedgeCount':
        case 'lastQtr5HedgeCount':
        case 'lastQtr6HedgeCount':
        case 'lastQtr7HedgeCount':
        case 'lastQtr8HedgeCount':
        case 'totalInstMfShares':
        case 'lastQtr2HedgeShares':
        case 'lastQtr3HedgeShares':
        case 'lastQtr4HedgeShares':
        case 'lastQtr5HedgeShares':
        case 'lastQtr6HedgeShares':
        case 'lastQtr7HedgeShares':
        case 'lastQtr8HedgeShares':
        case 'rishiPrevShares':
        case 'rishiCurrShares':
        case 'shares':
        case 'count':
        case 'searchCount':
        case 'soldOutCount':
        case 'soldOutShares':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, fontSize: 12 }}
              >
                {formatNumber2(text)}
              </p>
            ),
          };

        case 'lastQtr1HedgeHeroShares':
          return {
            ...baseProperties,
            title: rowTitleWithToolTip(
              `(Jim Simmons) Renaissance Technologies ${getDisplayableQtr()} Shares`,
              <QuestionCircleOutlined />,
              value,
            ),
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, fontSize: 12 }}
              >
                {formatNumber2(text)}
              </p>
            ),
          };
        case 'lastQtr1HedgeHeroStatus':
          return {
            ...baseProperties,
            sorter: false,
            title: rowTitleWithToolTip(
              `(Jim Simmons) Renaissance Technologies ${getDisplayableQtr()} Shares`,
              <QuestionCircleOutlined />,
              value,
            ),
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  ...baseStyling,
                  fontSize: 12,
                  color: getColorForStatus(text, theme),
                }}
              >
                {text}
              </p>
            ),
          };
        case 'ytd':
        case 'oneYrGrowth':
        case 'priceChangePerc':
        case 'lastQ1Allocation':
        case 'lastQ2Allocation':
        case 'lastQ3Allocation':
        case 'lastQ4Allocation':
        case 'lastQ5Allocation':
        case 'lastQ6Allocation':
        case 'lastQ7Allocation':
        case 'lastQ8Allocation':
        case 'lastQ1DivPerc':
        case 'lastQ2DivPerc':
        case 'lastQ3DivPerc':
        case 'lastQ4DivPerc':
        case 'lastQ5DivPerc':
        case 'lastQ6DivPerc':
        case 'lastQ7DivPerc':
        case 'lastQ8DivPerc':
        case 'sharesChangePer':
        case 'ownership':
        case 'price200DayChange':
        case 'price150DayChange':
        case 'price20DayChange':
        case 'price10DayChange':
        case 'price50DayChange':
        case 'price1DayChange':
        case 'price5DayChange':
        case 'price100DayChange':
        case 'insidersPerc':
        case 'instPerc':
        case 'eps_perc':
        case 'changePerc':
        case 'pctchange':
        case 'allocationPerc':
        case 'allInstAlloc':
        case 'topHedgeAlloc':
        case 'instCountChange':
        case 'instSharesChange':
        case 'gainInWeeks':
        case 'lossInWeeks':
        case 'gainInDays':
        case 'lossInDays':
        case 'lastQtrCountChange':
        case 'percentage':
        case 'lastQtrSharesChange':
        case 'lastQtrAvgChange':
        case 'lastQtrVolumeChange':
        case 'lastQtrTotalSharesChange':
        case 'lastQtr1PutCountsRatio':
        case 'lastQtr1PutContractsRatio':
        case 'lastQtr1CallCountsRatio':
        case 'lastQtr1ContractsRatio':
        case 'lastQtr1CallContractsRatio':
        case 'lastQtr1PutRatio':
        case 'lastQtr1CallRatio':
        case 'lastQtr1PutInstContractsRatio':
        case 'lastQtr1CallInstContractsRatio':
        case 'lastQtr1CallInstRatio':
        case 'lastQtr1PutInstRatio':
        case 'lastQtr1TotalCallInstRatio':
        case 'lastQtr1TotalPutInstRatio':
        case 'lastQtr1InstCallContractsRatio':
        case 'lastQtr1InstPutContractsRatio':
        case 'percentage_returns':
        case 'latestPeriodReturns':
        case 'invested_perc_returns':
        case 'overall_perc_returns':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  color: getColor(text, theme),
                  ...baseStyling,
                  fontSize: 12,
                }}
              >
                {formatNumberWithPercent(text)}
              </p>
            ),
          };
        case 'pctgain':
          return {
            ...baseProperties,
            title:
              source === 'compare'
                ? rowTitleWithToolTip(
                    `% Avg. Price change Between last 2 Qtrs`,
                    <QuestionCircleOutlined />,
                    value,
                  )
                : rowTitle(value),
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  color: getColor(text, theme),
                  ...baseStyling,
                  fontSize: 12,
                }}
              >
                {formatNumberWithPercent(text)}
              </p>
            ),
          };

        case 'totalGainInDays':
        case 'totalLossInDays':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text) => {
              const [priceGain, volGain] = (text || '0|0').split('|');
              return (
                <>
                  <p
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    style={{
                      color: getColor(priceGain, theme),
                      ...baseStyling,
                      fontSize: 12,
                    }}
                  >
                    {formatNumberWithPercent(priceGain)}
                  </p>

                  <p
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    style={{
                      color: getColor(volGain, theme),
                      ...baseStyling,
                      fontSize: 12,
                    }}
                  >
                    {formatNumberWithPercent(volGain)}
                  </p>
                </>
              );
            },
          };
        case 'totalDivPercLast1Yr':
        case 'totalDivPercLast2Yr':
        case 'totalDivPercLast3Yr':
        case 'totalDivPercLast4Yr':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            sorter: false,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  color: getColor(text, theme),
                  ...baseStyling,
                  fontSize: 12,
                }}
              >
                {formatNumberWithPercent(text)}
              </p>
            ),
          };
        case 'fiveYrGrowth':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  color: getColor(text, theme),
                  ...baseStyling,
                  fontSize: 12,
                }}
              >
                {formatNumberWithPercent(text)}
              </p>
            ),
          };
        case 'changeHedgeCountStatus':
        case 'changeHedgeSharesStatus':
        case 'changeSharesStatus': {
          return {
            ...baseProperties,
            width: source === 'watchList' ? 130 : ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  color: getColorForStatus(text, theme),
                  ...baseStyling,
                  fontSize: 12,
                }}
              >
                {isNaN(text) ? text : formatNumberWithPercent(text)}
              </p>
            ),
          };
        }
        case 'c52WkLow':
        case 'c52WkHigh':
        case 'yearBeginPrice':
        case 'c5YrLow':
        case 'c5YrHigh':
        case 'priceOnRecDate':
        case 'ytdPriceChange':
        case 'beginPrice':
        case 'beginningPrice':
        case 'endPrice':
        case 'endingPrice':
        case 'priceChange':
        case 'latestPeriodChange':
        case 'lastQ1Dividend':
        case 'lastQ2Dividend':
        case 'lastQ3Dividend':
        case 'lastQ4Dividend':
        case 'lastQ5Dividend':
        case 'lastQ6Dividend':
        case 'lastQ7Dividend':
        case 'lastQ8Dividend':
        case 'lastQtr1Dividend':
        case 'lastQtr2Dividend':
        case 'lastQtr3Dividend':
        case 'lastQtr4Dividend':
        case 'lastQtr5Dividend':
        case 'lastQtr6Dividend':
        case 'lastQtr7Dividend':
        case 'lastQtr8Dividend':
        case 'lastQtr1Avg':
        case 'lastQtr2Avg':
        case 'lastQtr3Avg':
        case 'lastQtr4Avg':
        case 'lastQtr5Avg':
        case 'lastQtr6Avg':
        case 'lastQtr7Avg':
        case 'lastQtr8Avg':
        case 'totalInstMfShareValue':
        case 'sharesValue':
        case 'lastWkAvg1':
        case 'lastWkAvg2':
        case 'lastWkAvg3':
        case 'lastWkAvg4':
        case 'lastWkAvg5':
        case 'lastWkAvg6':
        case 'lastWkAvg7':
        case 'lastWkAvg8':
        case 'lastWkAvg9':
        case 'lastWkAvg10':
        case 'lastWkAvg11':
        case 'lastWkAvg12':
        case 'lastWkAvg13':
        case 'lastWkAvg14':
        case 'lastWkAvg15':
        case 'lastWkAvg16':
        case 'lastWkAvg17':
        case 'lastWkAvg18':
        case 'lastWkAvg19':
        case 'lastWkAvg20':
        case 'lastWkAvg21':
        case 'lastWkAvg22':
        case 'lastWkAvg23':
        case 'lastWkAvg24':
        case 'lastWkAvg25':
        case 'lastWkAvg26':
        case 'lastWkAvg27':
        case 'lastWkAvg28':
        case 'lastWkAvg29':
        case 'lastWkAvg30':
        case 'lastWkAvg31':
        case 'lastWkAvg32':
        case 'lastWkAvg33':
        case 'lastWkAvg34':
        case 'lastWkAvg35':
        case 'lastWkAvg36':
        case 'lastWkAvg37':
        case 'lastWkAvg38':
        case 'lastWkAvg39':
        case 'lastWkAvg40':
        case 'lastWkAvg41':
        case 'lastWkAvg42':
        case 'lastWkAvg43':
        case 'lastWkAvg44':
        case 'lastWkAvg45':
        case 'lastWkAvg46':
        case 'lastWkAvg47':
        case 'lastWkAvg48':
        case 'lastWkAvg49':
        case 'lastWkAvg50':
        case 'lastWkAvg51':
        case 'lastWkAvg52':
        case 'lastDayPrice1':
        case 'lastDayPrice2':
        case 'lastDayPrice3':
        case 'lastDayPrice4':
        case 'lastDayPrice5':
        case 'lastDayPrice6':
        case 'lastDayPrice7':
        case 'lastDayPrice8':
        case 'lastDayPrice9':
        case 'lastDayPrice10':
        case 'lastDayPrice11':
        case 'lastDayPrice12':
        case 'lastDayPrice13':
        case 'lastDayPrice14':
        case 'lastDayPrice15':
        case 'lastDayPrice16':
        case 'lastDayPrice17':
        case 'lastDayPrice18':
        case 'lastDayPrice19':
        case 'lastDayPrice20':
        case 'lastDayPrice21':
        case 'lastDayPrice22':
        case 'lastDayPrice23':
        case 'lastDayPrice24':
        case 'lastDayPrice25':
        case 'lastDayPrice26':
        case 'lastDayPrice27':
        case 'lastDayPrice28':
        case 'lastDayPrice29':
        case 'lastDayPrice30':
        case 'price15minAgo':
        case 'yc_price':
        case 'highestPriceSinceReco':
        case 'invested_value':
        case 'current_value':
        case 'current_capital':
        case 'invested_amount_gain':
        case 'balance':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, fontSize: 12 }}
              >
                {currencyFormat(
                  note === 'MMS' ? text.split(' ')[0] : text,
                  false,
                  false,
                )}
                <span
                  hidden={note !== 'MMS'}
                  style={{
                    cursor: 'Pointer',
                    marginLeft: 5,
                    textAlign: 'center',
                    fontSize: 12,
                    userSelect: 'none',
                    color: getColor(
                      text && text.length ? text.split(' ')[2] : 0,
                      theme,
                    ),
                  }}
                >
                  (
                  {formatNumberWithPercent(
                    text && text.length ? text.split(' ')[2] : 0,
                  )}
                  )
                </span>
              </p>
            ),
          };
        case 'totalPaidLast1Yr':
        case 'totalPaidLast2Yr':
        case 'totalPaidLast3Yr':
        case 'totalPaidLast4Yr':
          return {
            ...baseProperties,
            // sorter: false,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, fontSize: 12 }}
              >
                {currencyFormat(
                  note === 'MMS' ? text.split(' ')[0] : text,
                  false,
                  false,
                )}
                <span
                  hidden={note !== 'MMS'}
                  style={{
                    cursor: 'Pointer',
                    marginLeft: 5,
                    textAlign: 'center',
                    fontSize: 12,
                    userSelect: 'none',
                    color: getColor(
                      text && text.length ? text.split(' ')[2] : 0,
                      theme,
                    ),
                  }}
                >
                  (
                  {formatNumberWithPercent(
                    text && text.length ? text.split(' ')[2] : 0,
                  )}
                  )
                </span>
              </p>
            ),
          };
        case 'lastDayPriceAndVol1':
        case 'lastDayPriceAndVol2':
        case 'lastDayPriceAndVol3':
        case 'lastDayPriceAndVol4':
        case 'lastDayPriceAndVol5':
        case 'lastDayPriceAndVol6':
        case 'lastDayPriceAndVol7':
        case 'lastDayPriceAndVol8':
        case 'lastDayPriceAndVol9':
        case 'lastDayPriceAndVol10':
        case 'lastDayPriceAndVol11':
        case 'lastDayPriceAndVol12':
        case 'lastDayPriceAndVol13':
        case 'lastDayPriceAndVol14':
        case 'lastDayPriceAndVol15':
        case 'lastDayPriceAndVol16':
        case 'lastDayPriceAndVol17':
        case 'lastDayPriceAndVol18':
        case 'lastDayPriceAndVol19':
        case 'lastDayPriceAndVol20':
        case 'lastDayPriceAndVol21':
        case 'lastDayPriceAndVol22':
        case 'lastDayPriceAndVol23':
        case 'lastDayPriceAndVol24':
        case 'lastDayPriceAndVol25':
        case 'lastDayPriceAndVol26':
        case 'lastDayPriceAndVol27':
        case 'lastDayPriceAndVol28':
        case 'lastDayPriceAndVol29':
        case 'lastDayPriceAndVol30':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text) => {
              const [price, vol] = (text || '0|0').split('|');
              return (
                <>
                  <p
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    style={{ ...baseStyling, fontSize: 12 }}
                  >
                    {currencyFormat(price, false, false)}
                  </p>
                  <p
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    style={{ ...baseStyling, fontSize: 12 }}
                  >
                    {formatNumber2(vol)}
                  </p>
                </>
              );
            },
          };
        case 'week_1':
        case 'week_2':
        case 'week_3':
        case 'week_4':
        case 'week_5':
        case 'week_6':
        case 'week_7':
        case 'week_8':
        case 'week_9':
        case 'week_10':
        case 'week_11':
        case 'week_12':
        case 'week_13':
        case 'week_14':
        case 'week_15':
        case 'week_16':
        case 'week_17':
        case 'week_18':
        case 'week_19':
        case 'week_20':
        case 'week_21':
        case 'week_22':
        case 'week_23':
        case 'week_24':
        case 'week_25':
        case 'week_26':
        case 'week_27':
        case 'week_28':
        case 'week_29':
        case 'week_30':
        case 'week_31':
        case 'week_32':
        case 'week_33':
        case 'week_34':
        case 'week_35':
        case 'week_36':
        case 'week_37':
        case 'week_38':
        case 'week_39':
        case 'week_40':
        case 'week_41':
        case 'week_42':
        case 'week_43':
        case 'week_44':
        case 'week_45':
        case 'week_46':
        case 'week_47':
        case 'week_48':
        case 'week_49':
        case 'week_50':
        case 'week_51':
        case 'week_52':
          return {
            ...baseProperties,
            sorter: getSorterValue(key),
            width: ColumnWidths[key] || 80,
            render: (text) => {
              const [percentage_change, , , weekend_price] = (
                text || '0,0,0,0'
              ).split(',');
              return (
                <>
                  <p
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    style={{ ...baseStyling, fontSize: 12 }}
                  >
                    {currencyFormat(weekend_price, false, false)}
                  </p>
                  <p
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    style={{
                      ...baseStyling,
                      color: getColor(percentage_change, theme),
                      ...baseStyling,
                      fontSize: 12,
                    }}
                  >
                    {formatNumberWithPercent(percentage_change)}
                  </p>
                </>
              );
            },
          };
        case 'transactionPrice':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, fontSize: 12 }}
              >
                {currencyFormat2(text, 2)}
              </p>
            ),
          };
        case 'price1DayAgo':
        case 'price5DaysAgo':
        case 'price10DaysAgo':
        case 'price20DaysAgo':
        case 'price50DaysAgo':
        case 'price100DaysAgo':
        case 'price150DaysAgo':
        case 'price200DaysAgo':
          return {
            ...baseProperties,
            title: rowTitleWithToolTip(
              `${value.split(' ')[0]} Day${
                value.split(' ')[0] > 1 ? 's' : ''
              } Moving Average`,
              <QuestionCircleOutlined />,
              value,
            ),
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, fontSize: 12 }}
              >
                {currencyFormat(text, false, false)}
              </p>
            ),
          };

        case 'mv_avg_50d':
        case 'mv_avg_150d':
        case 'mv_avg_200d':
          return {
            ...baseProperties,
            title: rowTitleWithToolTip(
              `${value.substring(0, value.length - 3)} Days Moving Average`,
              <QuestionCircleOutlined />,
              value,
            ),
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, fontSize: 12 }}
              >
                {currencyFormat(text.split(' ')[0], false, false)}
                <span
                  hidden={!text.split(' ')[2]}
                  style={{
                    cursor: 'Pointer',
                    marginLeft: 5,
                    textAlign: 'center',
                    fontSize: 12,
                    userSelect: 'none',
                    color: getColor(text.split(' ')[2], theme),
                  }}
                >
                  (
                  {formatNumberWithPercent(
                    text && text.length ? text.split(' ')[2] : 0,
                  )}
                  )
                </span>
              </p>
            ),
          };
        case 'relative_strength_index':
          return {
            ...baseProperties,
            title: rowTitleWithToolTip(
              `Relative Strength Index`,
              <QuestionCircleOutlined />,
              value,
            ),
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, fontSize: 12 }}
              >
                {(+text).toFixed(2)}
              </p>
            ),
          };
        case 'student_name':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text, record) => (
              <div onClick={() => stocksModal(text, record)}>
                <Tooltip
                  placement="top"
                  title={`click here to view ${text} portfolio`}
                  color={green}
                  overlayStyle={{
                    color: black1,
                    width: 220,
                  }}
                >
                  <div
                    style={{
                      margin: '-7px',
                      cursor: 'pointer',
                      color: green,
                      fontWeight: 700,
                      textDecoration: 'underline',
                    }}
                  >
                    <p
                      onCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      style={{
                        ...baseStyling,
                        fontSize: 12,
                      }}
                    >
                      {text}
                    </p>
                  </div>
                </Tooltip>
              </div>
            ),
          };
        case 'lastSale':
        case 'price':
        case 'last_sale':
        case 'year_start_price':
        case 'currwkavg':
        case 'prevwkavg':
        case 'high':
        case 'low':
        case 'entryPrice':
        case 'currentSalePrice':
        case 'start_price':
        case 'end_price':
        case 'priceOnDateAdded':
          return {
            ...baseProperties,
            title: rowCurrentPriceTitle(title || value, source),
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, fontSize: 12 }}
              >
                {currencyFormat(text, false, false, 2)}
              </p>
            ),
          };
        case 'trxn_val_min':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 100,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, fontSize: 12 }}
              >
                {'$' + text / 1000000 + 'M'}
              </p>
            ),
          };
        // case 'investedValue':
        // case 'lastQtr1InvestedValue':
        // case 'lastQtr1HedgeInvested':
        // case 'tmv':
        // case 'lastQtr1HedgeInvestedValue':
        //   return {
        //     ...baseProperties,
        //     width: ColumnWidths[key] || 80,
        //     render: (text) => (
        //       <p
        //         onCopy={(e) => {
        //           e.preventDefault();
        //           return false;
        //         }}
        //         style={{ ...baseStyling, fontSize: 12 }}
        //       >
        //         {currencyFormat3(text)}
        //       </p>
        //     ),
        //   };
        case 'totalCost':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  ...baseStyling,
                  fontSize: 12,
                }}
              >
                {currencyFormat2(text, 4)}
              </p>
            ),
          };
        case 'equity':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  ...baseStyling,
                  fontSize: 12,
                }}
              >
                {currencyFormat(text, false, false, 2)}
              </p>
            ),
          };
        case 'avg_cost':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  ...baseStyling,
                  fontSize: 12,
                }}
              >
                {currencyFormat2(text, 4)}
              </p>
            ),
          };
        case 'price_on_entry':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, fontSize: 12 }}
              >
                {currencyFormat(text, false, false, 4)}
              </p>
            ),
          };
        case 'priceDiff':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  ...baseStyling,
                  color: getColor(text, theme),
                  fontSize: 12,
                }}
              >
                {currencyFormat(text, false, false)}
              </p>
            ),
          };
        case 'totalReturns':
        case 'todayReturns':
        case 'dollarChange':
          return {
            ...baseProperties,
            width: source === 'watchList' ? 80 : ColumnWidths[key] || 80,
            // sorter: (a, b) => a.key - b.key,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  ...baseStyling,
                  color: getColor(text, theme),
                  fontSize: 12,
                }}
              >
                {currencyFormat(text, false, false, 2)}
              </p>
            ),
          };
        case 'no_of_shares':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <div>
                <Tooltip
                  placement="bottom"
                  title={
                    <div
                      style={{
                        padding: '0px 30px 0px 30px',
                        borderRadius: '5px',
                        backgroundColor: '#1b1a1a',
                      }}
                    >
                      {text}
                    </div>
                  }
                >
                  <div style={{ margin: '-7px' }}>
                    <p
                      onCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      style={{
                        ...baseStyling,
                        fontSize: 12,
                      }}
                    >
                      {text ? formatNumber2(text) : '-'}
                    </p>
                  </div>
                </Tooltip>
              </div>
            ),
          };
        case 'investedValue':
        case 'lastQtr1InvestedValue':
        case 'lastQtr1HedgeInvested':
        case 'lastQtr1HedgeInvestedValue':
        case 'lastQtr1HedgeMarketValue':
        case 'soldOutValue':
        case 'marketValue':
        case 'transactionValue':
          return {
            ...baseProperties,
            title:
              note === 'countStatus' && key === 'marketValue'
                ? rowTitleWithToolTip(
                    `Shares Diff * Current Price`,
                    <QuestionCircleOutlined />,
                    value,
                  )
                : rowTitle(title || value),
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  ...baseStyling,
                  color: text < 0 ? red : green,
                  fontSize: 12,
                }}
              >
                {currencyFormat(text, false, false, 2, false, true)}
              </p>
            ),
          };
        case 'ApprxInvestedVal':
          return {
            ...baseProperties,
            title:
              note === 'countStatus' && key === 'marketValue'
                ? rowTitleWithToolTip(
                    `Shares Diff * Current Price`,
                    <QuestionCircleOutlined />,
                    value,
                  )
                : note === 'IST'
                ? rowTitleWithToolTip(
                    multiStatus === 'NEWLY_BOUGHT'
                      ? `Shares Diff * ${getDisplayableQtr()} Avg Price`
                      : multiStatus === 'SOLD_OFF' ||
                        multiStatus === 'NO_CHANGE'
                      ? `Shares Diff * ${getDisplayablePrevQtr()} Avg Price`
                      : `Shares Diff * Last Qtr Avg Price`,
                    <QuestionCircleOutlined />,
                    value,
                  )
                : rowTitle(title || value),
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  ...baseStyling,
                  fontSize: 12,
                }}
              >
                {currencyFormat(text, false, false, 2, false, true)}
              </p>
            ),
          };
        case 'lastQtr1ApproxInvestedValue':
          // console.log('lastQtr1ApproxInvestedValue', title, value)
          return {
            ...baseProperties,
            title: getlastQtr1ApproxInvestedValueTitle(value),
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  ...baseStyling,
                  color: text < 0 ? red : green,
                  fontSize: 12,
                }}
              >
                {currencyFormat(text, false, false, 2, false, true)}
              </p>
            ),
          };
        case 'top10HedgeFundsIn_NC_NB_CNT':
        case 'top10InstIn_NC_NB_CNT':
          return {
            ...baseProperties,
            title: getlastQtr1ApproxInvestedValueTitle2(value),
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  ...baseStyling,
                  fontSize: 12,
                }}
              >
                {text}
              </p>
            ),
          };

        case 'aum':
        case 'totalAum':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            title: rowTitleWithToolTip(
              'Last Qtr Avg Price * Total Shares',
              <InfoCircleOutlined />,
              value,
            ),
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, fontSize: 12 }}
              >
                {currencyFormat(text, false, false, 2, false, true)}
              </p>
            ),
          };
        case 'lastQtr1Aum':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            title: rowTitleWithToolTip(
              `(Increased, Reduced, no change shares) * ${
                value.split(' ')[0]
              } Avg price`,
              <InfoCircleOutlined />,
              value,
            ),
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, fontSize: 12 }}
              >
                {currencyFormat(text, false, false, 2, false, true)}
              </p>
            ),
          };
        case 'lastQtr2Aum':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            title: rowTitleWithToolTip(
              `(Increased, Reduced, no change shares) * ${
                value.split(' ')[0]
              } Avg price`,
              <InfoCircleOutlined />,
              value,
            ),
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, fontSize: 12 }}
              >
                {currencyFormat(text, false, false, 2, false, true)}
              </p>
            ),
          };
        case 'aumDiff':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            title: rowTitleWithToolTip(
              'Prev Qtr AUM - Last Qtr AUM',
              <InfoCircleOutlined />,
              value,
            ),
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, fontSize: 12 }}
              >
                {currencyFormat(text, false, false, 2, false, true)}
              </p>
            ),
          };
        case 'tmv':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            title: rowTitleWithToolTip(
              'Current Price * Last Qtr Shares',
              <InfoCircleOutlined />,
              value,
            ),
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, fontSize: 12 }}
              >
                {currencyFormat(text, false, false, 2, false, true)}
              </p>
            ),
          };
        case 'mCap':
        case 'marketCap':
        case 'live_cash':
        case 'revenueEstimate':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, fontSize: 12 }}
              >
                {text ? currencyFormat3(text, true) : '-'}
              </p>
            ),
          };
        case 'name':
          return {
            ...baseProperties,
            width: source === 'stock_table' ? 100 : ColumnWidths[key],
            fixed: source === 'isr' ? 'left' : false,
            render: (text) => (
              <p
                // onCopy={(e) => {
                //   e.preventDefault();
                //   return false;
                // }}
                onCopy={() => navigator.clipboard.writeText(text)}
                style={{
                  ...baseStyling,
                  fontSize: 10,
                  wordBreak: 'break-all',
                  userSelect: 'text',
                }}
              >
                {' '}
                {text && text.toUpperCase()}
              </p>
            ),
          };
        case 'ownerName':
          return {
            ...baseProperties,
            width: ColumnWidths[key],
            render: (text) => (
              <p
                onCopy={() => navigator.clipboard.writeText(text)}
                style={{
                  ...baseStyling,
                  fontSize: 12,
                  wordBreak: 'break-all',
                  userSelect: 'text',
                }}
              >
                {text && text.toUpperCase()}
              </p>
            ),
          };
        case 'transactionDate':
          return {
            ...baseProperties,
            width: ColumnWidths[key],
            render: (text) => (
              <p
                style={{
                  ...baseStyling,
                  fontSize: 12,
                  wordBreak: 'break-all',
                  // userSelect: 'auto',
                }}
              >
                {text ? moment(text).format('DD MMM YYYY') : '-'}
              </p>
            ),
          };
        case 'companyName':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            fixed: source === 'isr' ? 'left' : false,
            render: (text) => (
              <p
                // onCopy={(e) => {
                //   e.preventDefault();
                //   return false;
                // }}
                onCopy={() => navigator.clipboard.writeText(text)}
                style={{
                  ...baseStyling,
                  fontSize: 10,
                  wordBreak: 'break-all',
                  userSelect: 'auto',
                }}
              >
                {' '}
                {text && text.toUpperCase()}
              </p>
            ),
          };
        case 'institutionName':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            fixed: source === 'isr' ? 'left' : false,
            render: (text, record) => (
              <p
                // onCopy={(e) => {
                //   e.preventDefault();
                //   return false;
                // }}
                onCopy={() => navigator.clipboard.writeText(text)}
                onClick={async () => {
                  const filters = {
                    type: 'INSTITUTION'.toLowerCase(),
                    subtype: 'INDIVIDUAL_INSTITUTION_REPORT'.toLowerCase(),
                    institutions: [record.inst_cik || (await getCIK(text))],
                    institutionsName: [text],
                  };
                  if (history && text !== 'Total') {
                    handleISRTOIIR(filters);
                    // history.push(
                    //   `/search/report/` + queryString.stringify(filters),
                    // );
                    //movetoIIR(filters, history);
                  }
                }}
                style={
                  source === 'isr'
                    ? {
                        wordBreak: 'break-all',
                        color: green,
                        fontWeight: 700,
                        cursor:
                          text === 'Total' || !history ? 'auto' : 'Pointer',
                        textDecoration:
                          text === 'Total' || !history ? 'none' : 'underline',
                        ...baseStyling,
                        userSelect: 'text',
                        fontSize: 10,
                      }
                    : {
                        ...baseStyling,
                        fontSize: 10,
                        wordBreak: 'break-all',
                        userSelect: 'text',
                      }
                }
              >
                {' '}
                {text && text.toUpperCase()}
              </p>
            ),
          };
        case 'allocation':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            title: rowTitleWithToolTip(
              '(Last Qtr Shares * Last Qtr Avg Price * 100) / AUM',
              <InfoCircleOutlined />,
              value,
            ),
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, fontSize: 12 }}
              >
                {text ? `${formatNumber(text, 4)} %` : '-'}
              </p>
            ),
          };

        case 'change':
        case 'perChange':
        case 'PtcChgnWtEntryPrc':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  ...baseStyling,
                  color: getColor(text, theme),
                  fontSize: 12,
                }}
              >
                {formatNumberWithPercent(text)}
              </p>
            ),
          };
        case 'possGrowth':
        case 'possVolGrowth':
        case 'percentagechange':
        case 'pos_ret_perc':
        case 'neg_ret_perc':
        case 'aumDiffPerc':
          return {
            ...baseProperties,
            title:
              source === 'diamonds' || value === 'Possible Growth'
                ? rowTitleWithToolTip(
                    note ===
                      'Possible Growth = [((52 W High - LastSale) / LastSale) * 100]'
                      ? '(5 Yr high - CurrentPrice ) * 100.0 / CurrentPrice'
                      : '(52 week high - CurrentPrice ) * 100.0 / CurrentPrice',
                    <InfoCircleOutlined />,
                    value + '%',
                    null,
                    note ===
                      'Possible Growth = [((52 W High - LastSale) / LastSale) * 100]'
                      ? '(52 week high - CurrentPrice ) * 100.0 / CurrentPrice , '
                      : null,
                  )
                : rowTitle(value + '%'),
            width:
              note ===
              'Possible Growth = [((52 W High - LastSale) / LastSale) * 100]'
                ? 120
                : ColumnWidths[key] || 80,
            render: (text) => (
              <>
                <p
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  style={{
                    ...baseStyling,
                    color: getColor(text.split(' ')[0], theme),
                    fontSize: 12,
                  }}
                >
                  {note ===
                  'Possible Growth = [((52 W High - LastSale) / LastSale) * 100]'
                    ? formatNumberWithPercent(text.split(' ')[0]) +
                      ' ( Based On 52W high ) ,'
                    : formatNumberWithPercent(text)}
                </p>
                <p
                  hidden={
                    note !==
                    'Possible Growth = [((52 W High - LastSale) / LastSale) * 100]'
                  }
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  style={{
                    ...baseStyling,
                    color: getColor(text.split(' ')[1], theme),
                    fontSize: 12,
                  }}
                >
                  {formatNumberWithPercent(text.split(' ')[1]) +
                    ' ( Based On 5yr high ) '}
                </p>
              </>
            ),
          };
        case 'sec13dCount':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            title: rowTitleWithToolTip(
              getSec13CountTooltip(note),
              <InfoCircleOutlined />,
              value,
            ),
            render: (text, record) => {
              const date = getCurrentSECDateRange();
              return text > 0 ? (
                <Link
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  style={{
                    ...baseStyling,
                    fontSize: 12,
                    color: getColor(text, theme),
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  to={
                    `/search/report/` +
                    queryString.stringify({
                      type: 'SEC_13_DG'.toLowerCase(),
                      subtype: 'DETAIL_SUMMARY_REPORT'.toLowerCase(),
                      symbols: record.symbol,
                      multiStatus:
                        filterOptions &&
                        filterOptions.multiStatus &&
                        note === 'SDL'
                          ? filterOptions.multiStatus
                          : (filterOptions &&
                              filterOptions.status === 'INCREASED') ||
                            (note === 'SAV' && !filterOptions.status)
                          ? ['INCREASED', 'NEWLY_BOUGHT']
                          : filterOptions &&
                            filterOptions.status === 'DECREASED'
                          ? ['DECREASED', 'SOLD_OFF']
                          : [],
                      startDate:
                        filterOptions &&
                        filterOptions.startDate &&
                        note !== 'DIA'
                          ? filterOptions.startDate
                          : note === 'SDL'
                          ? moment().subtract(1, 'years').format('YYYY-MM-DD')
                          : moment(date.start).format('YYYY-MM-DD'),
                      endDate:
                        filterOptions && filterOptions.endDate
                          ? filterOptions.endDate
                          : moment().format('YYYY-MM-DD'),
                    })
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formatNumber2(text)}
                </Link>
              ) : (
                <p
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  style={{
                    ...baseStyling,
                    fontSize: 12,
                    color: getColor(text, theme),
                    cursor: text > 0 ? 'pointer' : 'auto',
                  }}
                >
                  {formatNumber2(text)}
                </p>
              );
            },
          };

        case 'totalReturnsPerc':
        case 'todayReturnsPerc':
        case 'dollarChangePerc':
        case 'returnsPerc':
        case 'changeCountsStatus':
        case 'changeShareStatus':
          return {
            ...baseProperties,
            width: source === 'watchList' ? 80 : ColumnWidths[key] || 80,
            // sorter: false,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  ...baseStyling,
                  color: getColor(text, theme),
                  fontSize: 12,
                }}
              >
                {isNaN(text) ? text : formatNumberWithPercent(text)}
              </p>
            ),
          };
        case 'upcoming_catalyst':
          return {
            ...baseProperties,
            // fixed: 'right',
            width: ColumnWidths[key] || 80,
            title: rowTitle(value),
            sorter: false,
            render: (text, record) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onClick={() => showDetail(record, 'catalyst')}
                style={{
                  ...baseStyling,
                  color: green,
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
              >
                Catalyst
              </p>
            ),
          };
        case 'forecastedClosingPrices':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text) => (
              <div
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling }}
              >
                {text}
                {/* <table id="forecastingTable">
                  <tr>
                    <th colSpan="3">Market Condition</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th>UP</th>
                    <th>DOWN</th>
                  </tr>
                  <tr>
                    <td>
                      PT1
                      <Tooltip
                        placement="top"
                        title={'Price Target'}
                        color={green}
                        overlayStyle={{
                          color: black1,
                          width: 100,
                        }}
                      >
                        <span
                          style={{
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            paddingLeft: 10,
                          }}
                        >
                          <QuestionCircleOutlined size={16} />
                        </span>
                      </Tooltip>
                    </td>
                    <td>{`${currencyFormat(data[0][0])}`}</td>
                    <td>{`${currencyFormat(data[1][0])}`}</td>
                  </tr>
                  <tr>
                    <td>
                      PT2
                      <Tooltip
                        placement="top"
                        title={'Price Target'}
                        color={green}
                        overlayStyle={{
                          color: black1,
                          width: 100,
                        }}
                      >
                        <span
                          style={{
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            paddingLeft: 10,
                          }}
                        >
                          <QuestionCircleOutlined size={16} />
                        </span>
                      </Tooltip>
                    </td>
                    <td>{`${currencyFormat(data[0][1])}`}</td>
                    <td>{`${currencyFormat(data[1][1])}`}</td>
                  </tr>
                  <tr>
                    <td>
                      SL1
                      <Tooltip
                        placement="top"
                        title={'Stop Loss 10%'}
                        color={green}
                        overlayStyle={{
                          color: black1,
                          width: 120,
                        }}
                      >
                        <span
                          style={{
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            paddingLeft: 10,
                          }}
                        >
                          <QuestionCircleOutlined size={16} />
                        </span>
                      </Tooltip>
                    </td>
                    <td>{`-`}</td>
                    <td>{`${currencyFormat(data[2][0])}`}</td>
                  </tr>
                  <tr>
                    <td>
                      SL2
                      <Tooltip
                        placement="top"
                        title={'Stop Loss 15%'}
                        color={green}
                        overlayStyle={{
                          color: black1,
                          width: 120,
                        }}
                      >
                        <span
                          style={{
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            paddingLeft: 10,
                          }}
                        >
                          <QuestionCircleOutlined size={16} />
                        </span>
                      </Tooltip>
                    </td>
                    <td>{`-`}</td>
                    <td>{`${currencyFormat(data[2][1])}`}</td>
                  </tr>
                </table>*/}
              </div>
            ),
          };
        case 'changeStatus':
        case 'changeCountStatus':
        case 'companyInstCountStatus':
          return {
            ...baseProperties,
            sorter: false,
            width: ColumnWidths[key] || 80,
            render: (text, record) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  ...baseStyling,
                  fontSize: 10,
                  color:
                    source !== 'alert' ? getColorForStatus(text, theme) : '',
                }}
              >
                {isNaN(text) && source !== 'alert'
                  ? formatStatus(text, source, record)
                  : source === 'alert'
                  ? formatStatusText(text, source, record)
                  : formatNumber2(text)}
              </p>
            ),
          };
        case 'specialStatus':
        case 'status':
          return {
            ...baseProperties,
            sorter: isStatusSort,
            width: ColumnWidths[key] || 80,
            render: (text, record) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onClick={async () => {
                  const statusParamsMap = {
                    INCREASED: 'INCREASED',
                    REDUCED: 'DECREASED',
                    'SOLD OFF': 'SOLD_OFF',
                    SOLD_OFF: 'SOLD_OFF',
                    NEWLY_BOUGHT: 'NEWLY_BOUGHT',
                    'NEWLY BOUGHT': 'NEWLY_BOUGHT',
                    NO_CHANGE: 'NO_CHANGE',
                    'NO CHANGE': 'NO_CHANGE',
                  };
                  const filters = {
                    type: 'SYMBOL'.toLowerCase(),
                    subtype: 'INDIVIDUAL_STATUS_REPORT'.toLowerCase(),
                    symbols: filterOptions,
                    multiStatus: [statusParamsMap[text.toUpperCase()]],
                  };
                  if (text !== 'Total' && history && source === 'status') {
                    handleISRTOStatusReport(filters);
                    // history.push(
                    //   `/search/report/` + queryString.stringify(filters),
                    // );
                    //movetoIIR(filters, history);
                  }
                }}
                style={{
                  ...baseStyling,
                  fontSize: 10,
                  cursor: source === 'status' ? 'Pointer' : '',
                  textDecoration: source === 'status' ? 'underline' : '',
                  color:
                    source !== 'alert' ? getColorForStatus(text, theme) : '',
                }}
              >
                {isNaN(text) && source !== 'alert'
                  ? formatStatus(text, source, record)
                  : source === 'alert'
                  ? formatStatusText(text, source, record)
                  : formatNumber2(text)}
              </p>
            ),
          };
        case 'ts_status':
          return {
            ...baseProperties,
            width: ColumnWidths[key] || 80,
            render: (text, record) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  ...baseStyling,
                  fontSize: 10,
                  color: getColorForStatus(text, theme),
                }}
              >
                {isNaN(text)
                  ? formatStatus(text, source, record)
                  : formatNumber2(text)}
              </p>
            ),
          };
        case 'delete':
          return {
            ...baseProperties,
            fixed: 'right',
            width: 80,
            title: rowTitle(title),
            render: (text, record) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onClick={() => onDelete(record)}
                style={{ ...baseStyling, color: red, cursor: 'pointer' }}
              >
                <DeleteIcon />
              </p>
            ),
          };
        case 'edit':
          return {
            ...baseProperties,
            fixed: 'right',
            width: 80,
            title: rowTitle(title),
            render: (text, record) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onClick={() => onEdit(record)}
                style={{ ...baseStyling, color: green, cursor: 'pointer' }}
              >
                <EditOutlined />
              </p>
            ),
          };
        case 'edit_delete':
          return {
            ...baseProperties,
            fixed: 'right',
            width: 80,
            title: rowTitle(title),
            render: (text, record) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, cursor: 'pointer' }}
              >
                <EditOutlined
                  style={{ color: green, marginRight: 10, fontSize: 15 }}
                  onClick={() => onEdit(record)}
                  // size={20}
                />
                <DeleteIcon
                  style={{ color: red }}
                  onClick={() => onDelete(record)}
                />
              </p>
            ),
          };
        case 'acquired_or_disposed':
          return {
            ...baseProperties,
            width: ColumnWidths[key] ? ColumnWidths[key] : 65,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  ...baseStyling,
                  color: text.toUpperCase() === 'BUY' ? green : red,
                  fontSize: 12,
                }}
              >
                {text}
              </p>
            ),
          };
        case 'type':
          return {
            ...baseProperties,
            width:
              source === 'isr'
                ? 100
                : ColumnWidths[key]
                ? ColumnWidths[key]
                : 60,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  ...baseStyling,
                  // color: text.toUpperCase() === 'BUY' ? green : red,
                  fontSize: 12,
                }}
              >
                {text && text === 'SEC13DG' ? 'SEC13D/G' : text}
              </p>
            ),
          };
        case 'sec13dNewCount':
          return {
            ...baseProperties,
            width: ColumnWidths[key] ? ColumnWidths[key] : 80,
            title: rowTitle(sec13dNewCountTitle(value)),
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, fontSize: 12 }}
              >
                {formatNumber2(text)}
              </p>
            ),
          };
        case 'Avg90DayVol':
        case 'Avg200DayVol':
        case 'prevVolume':
        case 'outstandingShares':
          return {
            ...baseProperties,
            width: ColumnWidths[key] ? ColumnWidths[key] : 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, fontSize: 12 }}
              >
                {formatNumber2(text, 0, true)}
              </p>
            ),
          };
        case 'currVolume':
        case 'volume':
          return {
            ...baseProperties,
            width: ColumnWidths[key] ? ColumnWidths[key] : 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  ...baseStyling,
                  color: source === 'TS_ACTIVES_REPORT' ? green : '',
                  fontSize: 12,
                }}
              >
                {formatNumber2(text, 0, true)}
              </p>
            ),
          };
        case 'tsPrevVolume':
        case 'week_ago_volume':
        case 'month_ago_volume':
        case 'three_months_ago_volume':
        case 'six_months_ago_volume':
        case 'year_start_volume':
        case 'year_ago_volume':
        case 'five_years_ago_volume':
          return {
            ...baseProperties,
            width: ColumnWidths[key] ? ColumnWidths[key] : 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{
                  ...baseStyling,
                  color:
                    source === 'TS_ACTIVES_REPORT' &&
                    getTrendinngStocKVoulmeColor()
                      ? green
                      : '',
                  fontSize: 12,
                }}
              >
                {formatNumber2(text, 0, true)}
              </p>
            ),
          };

        default:
          return {
            ...baseProperties,
            width: ColumnWidths[key] ? ColumnWidths[key] : 80,
            render: (text) => (
              <p
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                style={{ ...baseStyling, fontSize: isNaN(text) ? 10 : 12 }}
              >
                {isNaN(text) ? text : formatNumber2(text)}
              </p>
            ),
          };
      }
    });

export const formatTotalRow = (col, source?: string) => {
  let baseStyling = {
    textAlign: 'center',
    fontSize: 12,
    margin: 'auto',
  };
  let data = [];
  for (const [key, value] of Object.entries(col)) {
    switch (key) {
      case 'rank':
        data.push(
          <p
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{ ...baseStyling, width: ColumnWidths[key] }}
          >
            {value}
          </p>,
        );
        break;

      case 'symbol':
        data.push(
          <p
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{
              ...baseStyling,
              fontSize: source === 'stock_table' ? 14 : 12,
              width: ColumnWidths[key] || 80,
            }}
          >
            {value}
          </p>,
        );
        break;
      case 'growthSinceRecommended':
      case 'highestGrowthSinceRecommended':
        data.push(
          <p
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{
              ...baseStyling,
              color: value < 0 ? red : green,
              width: ColumnWidths[key] || 80,
            }}
          >
            {formatNumberWithPercent(value)}
          </p>,
        );
        break;
      case 'date':
      case 'highestPriceDate':
      case 'created_at':
      case 'last_modified_at':
      case 'dateReported':
        data.push(
          <p
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{ ...baseStyling, width: ColumnWidths[key] }}
          >
            {value ? moment(value).format('DD MMM YYYY') : '-'}
          </p>,
        );
        break;
      case 'secDate':
        data.push(
          <p
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{ ...baseStyling, width: ColumnWidths[key] }}
          >
            {moment(value).tz('America/New_York').format('DD MMM YYYY')}
            <br />
            {moment(value).tz('America/New_York').format('hh:mm A')}
          </p>,
        );
        break;

      case 'sharesChange':
        data.push(
          <p
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{
              color: getColor(value),
              ...baseStyling,
              width: ColumnWidths[key] || 80,
              fontSize: 12,
            }}
          >
            {formatNumber2(value)}
          </p>,
        );
        break;

      case 'sector':
        data.push(
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              ...baseStyling,
              wordBreak: 'break-all',
              width: ColumnWidths[key] || 80,
            }}
          >
            {value && value.toUpperCase()}
          </span>,
        );
        break;

      case 'secForm':
        data.push(
          <a
            style={{
              textDecoration: 'underline',
              fontSize: 10,
              width: ColumnWidths[key] || 80,
              color: green,
            }}
            href={value}
            rel="noopener noreferrer"
            target="_blank"
          >
            WebLink
          </a>,
        );
        break;

      case 'secInstCount':
      case 'currentShares':
      case 'prevShares':
      case 'lastQtr1Count':
      case 'lastQtr2Count':
      case 'lastQtr3Count':
      case 'lastQtr4Count':
      case 'lastQtr5Count':
      case 'lastQtr6Count':
      case 'lastQtr7Count':
      case 'lastQtr8Count':
      case 'lastQtr1Shares':
      case 'lastQtr2Shares':
      case 'lastQtr3Shares':
      case 'lastQtr4Shares':
      case 'lastQtr5Shares':
      case 'lastQtr6Shares':
      case 'lastQtr7Shares':
      case 'lastQtr8Shares':
      case 'lastQtr1HedgeShares':
      case 'lastQtr1HedgeHeroShares':
      case 'sharesDiff':
      case 'totalSharesDiff':
      case 'lastQtr1HedgeCount':
      case 'lastQtr2HedgeCount':
      case 'lastQtr3HedgeCount':
      case 'lastQtr4HedgeCount':
      case 'lastQtr5HedgeCount':
      case 'lastQtr6HedgeCount':
      case 'lastQtr7HedgeCount':
      case 'lastQtr8HedgeCount':
      case 'totalInstMfShares':
      case 'lastQtr2HedgeShares':
      case 'lastQtr3HedgeShares':
      case 'lastQtr4HedgeShares':
      case 'lastQtr5HedgeShares':
      case 'lastQtr6HedgeShares':
      case 'lastQtr7HedgeShares':
      case 'lastQtr8HedgeShares':
      case 'rishiPrevShares':
      case 'rishiCurrShares':
      case 'shares':
      case 'count':
      case 'searchCount':
      case 'soldOutCount':
      case 'soldOutShares':
        data.push(
          <p
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{ ...baseStyling, fontSize: 12, width: ColumnWidths[key] }}
          >
            {formatNumber2(value)}
          </p>,
        );
        break;

      case 'ytd':
      case 'oneYrGrowth':
      case 'fiveYrGrowth':
      case 'priceChangePerc':
      case 'lastQ1Allocation':
      case 'lastQ2Allocation':
      case 'lastQ3Allocation':
      case 'lastQ4Allocation':
      case 'lastQ5Allocation':
      case 'lastQ6Allocation':
      case 'lastQ7Allocation':
      case 'lastQ8Allocation':
      case 'lastQ1DivPerc':
      case 'lastQ2DivPerc':
      case 'lastQ3DivPerc':
      case 'lastQ4DivPerc':
      case 'lastQ5DivPerc':
      case 'lastQ6DivPerc':
      case 'lastQ7DivPerc':
      case 'lastQ8DivPerc':
      case 'totalDivPercLast1Yr':
      case 'totalDivPercLast2Yr':
      case 'totalDivPercLast3Yr':
      case 'totalDivPercLast4Yr':
      case 'sharesChangePer':
      case 'ownership':
      case 'price200DayChange':
      case 'price150DayChange':
      case 'price20DayChange':
      case 'price10DayChange':
      case 'price50DayChange':
      case 'price1DayChange':
      case 'price5DayChange':
      case 'price100DayChange':
      case 'insidersPerc':
      case 'instPerc':
      case 'changePerc':
      case 'pctchange':
      case 'allocationPerc':
      case 'allInstAlloc':
      case 'topHedgeAlloc':
      case 'instCountChange':
      case 'instSharesChange':
      case 'gainInWeeks':
      case 'lastQtrCountChange':
      case 'lastQtrSharesChange':
      case 'lastQtrTotalSharesChange':
        data.push(
          <p
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{
              color: getColor(value),
              ...baseStyling,
              width: ColumnWidths[key] || 80,
              fontSize: 12,
            }}
          >
            {formatNumberWithPercent(value)}
          </p>,
        );

        break;
      case 'changeHedgeCountStatus':
      case 'changeHedgeSharesStatus':
      case 'changeSharesStatus':
        data.push(
          <p
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{
              color: isNaN(value) ? '' : getColor(value),
              ...baseStyling,
              width: source === 'watchList' ? 90 : ColumnWidths[key] || 80,
              fontSize: 12,
            }}
          >
            {isNaN(value) ? value : formatNumberWithPercent(value)}
          </p>,
        );

        break;
      case 'totalPaidLast1Yr':
      case 'totalPaidLast2Yr':
      case 'lastSale':
      case 'c52WkLow':
      case 'c52WkHigh':
      case 'yearBeginPrice':
      case 'price1DayAgo':
      case 'price5DaysAgo':
      case 'price10DaysAgo':
      case 'price20DaysAgo':
      case 'price50DaysAgo':
      case 'price100DaysAgo':
      case 'price200DaysAgo':
      case 'c5YrLow':
      case 'c5YrHigh':
      case 'priceOnRecDate':
      case 'ytdPriceChange':
      case 'beginPrice':
      case 'endPrice':
      case 'priceChange':
      case 'lastQ1Dividend':
      case 'lastQ2Dividend':
      case 'lastQ3Dividend':
      case 'lastQ4Dividend':
      case 'lastQ5Dividend':
      case 'lastQ6Dividend':
      case 'lastQ7Dividend':
      case 'lastQ8Dividend':
      case 'totalPaidLast3Yr':
      case 'totalPaidLast4Yr':
      case 'lastQtr1Dividend':
      case 'lastQtr2Dividend':
      case 'lastQtr3Dividend':
      case 'lastQtr4Dividend':
      case 'lastQtr5Dividend':
      case 'lastQtr6Dividend':
      case 'lastQtr7Dividend':
      case 'lastQtr8Dividend':
      case 'lastQtr1Avg':
      case 'lastQtr2Avg':
      case 'lastQtr3Avg':
      case 'lastQtr4Avg':
      case 'lastQtr5Avg':
      case 'lastQtr6Avg':
      case 'lastQtr7Avg':
      case 'lastQtr8Avg':
      case 'totalInstMfShareValue':
      case 'sharesValue':
      case 'lastWkAvg1':
      case 'lastWkAvg2':
      case 'lastWkAvg3':
      case 'lastWkAvg4':
      case 'lastWkAvg5':
      case 'lastWkAvg6':
      case 'lastWkAvg7':
      case 'lastWkAvg8':
      case 'lastWkAvg9':
      case 'lastWkAvg10':
      case 'lastWkAvg11':
      case 'lastWkAvg12':
      case 'lastWkAvg13':
      case 'lastWkAvg14':
      case 'lastWkAvg15':
      case 'lastWkAvg16':
      case 'lastWkAvg17':
      case 'lastWkAvg18':
      case 'lastWkAvg19':
      case 'lastWkAvg20':
      case 'lastWkAvg21':
      case 'lastWkAvg22':
      case 'lastWkAvg23':
      case 'lastWkAvg24':
      case 'lastWkAvg25':
      case 'lastWkAvg26':
      case 'lastWkAvg27':
      case 'lastWkAvg28':
      case 'lastWkAvg29':
      case 'lastWkAvg30':
      case 'lastWkAvg31':
      case 'lastWkAvg32':
      case 'lastWkAvg33':
      case 'lastWkAvg34':
      case 'lastWkAvg35':
      case 'lastWkAvg36':
      case 'lastWkAvg37':
      case 'lastWkAvg38':
      case 'lastWkAvg39':
      case 'lastWkAvg40':
      case 'lastWkAvg41':
      case 'lastWkAvg42':
      case 'lastWkAvg43':
      case 'lastWkAvg44':
      case 'lastWkAvg45':
      case 'lastWkAvg46':
      case 'lastWkAvg47':
      case 'lastWkAvg48':
      case 'lastWkAvg49':
      case 'lastWkAvg50':
      case 'lastWkAvg51':
      case 'lastWkAvg52':
      case 'transactionPrice':
      case 'price15minAgo':
      case 'yc_price':
      case 'week_ago_price':
      case 'month_ago_price':
      case 'three_months_ago_price':
      case 'six_months_ago_price':
      case 'year_start_price':
      case 'year_ago_price':
      case 'five_years_ago_price':
      case 'highestPriceSinceReco':
      case 'priceOnDateAdded':
        data.push(
          <p
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{ ...baseStyling, fontSize: 12, width: ColumnWidths[key] }}
          >
            {currencyFormat(value, false, false)}
          </p>,
        );
        break;
      case 'investedValue':
      case 'lastQtr1InvestedValue':
      case 'lastQtr1HedgeInvested':
      case 'tmv':
      case 'aum':
      case 'lastQtr1Aum':
      case 'lastQtr2Aum':
      case 'marketValue':
      case 'approxInvestedValue':
      case 'ApprxInvestedVal':
      case 'lastQtr1HedgeInvestedValue':
      case 'soldOutValue':
        data.push(
          <p
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{ ...baseStyling, fontSize: 12, width: ColumnWidths[key] }}
          >
            {currencyFormat(value, false, false, 2, false, true)}
          </p>,
        );
        break;
      case 'totalCost':
      case 'equity':
        data.push(
          <p
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{
              ...baseStyling,
              fontSize: source === 'stock_table' ? 14 : 12,
              width: ColumnWidths[key] || 80,
            }}
          >
            {currencyFormat(value, false, false, 2)}
          </p>,
        );
        break;
      case 'avg_cost':
        data.push(
          <p
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{
              ...baseStyling,
              fontSize: source === 'stock_table' ? 14 : 12,
              width: ColumnWidths[key] || 80,
            }}
          >
            {currencyFormat2(value, 4)}
          </p>,
        );
        break;
      case 'priceDiff':
      case 'totalReturns':
      case 'todayReturns':
      case 'dollarChange':
        data.push(
          <p
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{
              ...baseStyling,
              width: ColumnWidths[key] || 80,
              fontSize: source === 'stock_table' ? 14 : 12,
              color: getColor(value),
            }}
          >
            {currencyFormat(value, false, false, 2)}
          </p>,
        );
        break;
      // case 'aum':
      //   data.push(
      //     <p
      //       onCopy={(e) => {
      //         e.preventDefault();
      //         return false;
      //       }}
      //       style={{ ...baseStyling, fontSize: 12, width: ColumnWidths[key] }}
      //     >
      //       {currencyFormat(value, false, false)}
      //     </p>,
      //   );
      //   break;
      case 'name':
        data.push(
          <p
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{
              ...baseStyling,
              fontSize: 10,
              width: source === 'stock_table' ? 120 : ColumnWidths[key] || 80,
              wordBreak: 'break-all',
            }}
          >
            {' '}
            {value && value.toUpperCase()}
          </p>,
        );
        break;
      case 'companyName':
      case 'institutionName':
        data.push(
          <p
            // onCopy={(e) => {
            //   e.preventDefault();
            //   return false;
            // }}
            style={{
              ...baseStyling,
              fontSize: 10,
              width: ColumnWidths[key] || 80,
              wordBreak: 'break-all',
              userSelect: 'text',
            }}
          >
            {' '}
            {value && value.toUpperCase()}
          </p>,
        );
        break;
      case 'allocation':
      case 'change':
        data.push(
          <p
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{
              ...baseStyling,
              width: ColumnWidths[key] || 80,
              fontSize: 12,
            }}
          >
            {formatNumberWithPercent(value)}
          </p>,
        );
        break;
      case 'possGrowth':
      case 'possVolGrowth':
        data.push(
          <p
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{
              ...baseStyling,
              width: ColumnWidths[key] || 80,
              color: getColor(value),
              fontSize: 12,
            }}
          >
            {formatNumberWithPercent(value)}
          </p>,
        );
        break;
      case 'sec13dCount':
        data.push(
          <p
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{
              ...baseStyling,
              width: ColumnWidths[key] || 80,
              fontSize: 12,
            }}
          >
            {formatNumber2(value)}
          </p>,
        );
        break;
      case 'totalReturnsPerc':
      case 'todayReturnsPerc':
        data.push(
          <p
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{
              ...baseStyling,
              width: ColumnWidths[key] || 80,
              fontSize: source === 'stock_table' ? 14 : 12,
              color: getColor(value),
            }}
          >
            {formatNumberWithPercent(value)}
          </p>,
        );
        break;
      case 'status':
      case 'specialStatus':
      case 'changeStatus':
      case 'changeCountStatus':
        data.push(
          <p
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{
              ...baseStyling,
              width: ColumnWidths[key] || 80,
              fontSize: 10,
            }}
          >
            {isNaN(value) ? value : formatNumber2(value)}
          </p>,
        );
        break;
      case 'acquired_or_disposed':
        data.push(
          <p
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{
              ...baseStyling,
              color: value.toUpperCase() === 'BUY' ? green : red,
              fontSize: 12,
              width: ColumnWidths[key] ? ColumnWidths[key] : 60,
            }}
          >
            {value}
          </p>,
        );
        break;
      case 'type':
        data.push(
          <p
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{
              ...baseStyling,
              width: ColumnWidths[key] || 80,
              fontSize: 12,
            }}
          >
            {value && value === 'SEC13DG' ? 'SEC13D/G' : value}
          </p>,
        );
        break;
      default:
        data.push(
          <p
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{
              ...baseStyling,
              width: ColumnWidths[key] ? ColumnWidths[key] : 80,
              fontSize: isNaN(value) ? 10 : 12,
            }}
          >
            {isNaN(value) ? value : formatNumber2(value)}
          </p>,
        );
    }
  }
  return data;
};

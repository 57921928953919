import React, { useState, useEffect } from 'react';

import { HeartOutlined, RiseOutlined } from '@ant-design/icons';
import { Card, Cascader, message } from 'antd';
import { Tabs } from 'antd';
import { get } from 'lodash';
// import moment from 'moment-timezone';
import queryString from 'query-string';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import {
  getRecentAndSavedSearches,
  deletedSavedSearch,
  deletedRecentSearch,
  getQuarters,
  getIIRQuarters,
} from '../../graphql';
import { setReportQuarters, setIIRQuarters } from '../../redux/reducers/report';
import {
  setRecentSearches,
  setSavedSearches,
} from '../../redux/reducers/search';
import { errorHandler } from '../../utils';
import BuildYourOwnSearch from './BuildYourOwnSearch';
import EarningsCalendarSearch from './earningsCalendarReport';
import InstitutionSearch from './InstitutionSearch';
import InternalReportSearch from './InternalReportSearch';
import MarkMinerviniStrategySearch from './MarkMinerviniStrategySearch';
import MutualFundsSearch from './MutualFundsSearch';
import OptionSearch from './OptionSearch';
import PriceSearch from './PriceSearch';
import RecentSearches from './RecentSearches';
import SavedSearches from './SavedSearches';
import SEC13DGSearch from './SEC13DGSearch';
import SecInsiderSearch from './SecInsiderSearch';
import SectorSearch from './SectorSearch';
import styles from './style.module.scss';
import SymbolSearch from './SymbolSearch';
import ThinksabioDiamondsSearch from './thinksabio_diamonds';
import Top10HedgeSearch from './Top10HedgeSearch';
const { Meta } = Card;
const { TabPane } = Tabs;
interface Props extends RouteComponentProps<any> {}

const Search: React.FC<Props> = ({ history }) => {
  const { recentSearches, savedSearches, loggedInUser } = useSelector(
    ({ search, user }) => ({
      recentSearches: get(search, 'recentSearches', []),
      savedSearches: get(search, 'savedSearches', []),
      loggedInUser: get(user, 'loggedInUser', {}),
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    getQuarters()
      .then((res) => {
        dispatch(setReportQuarters(res));
      })
      .catch(errorHandler)
      .finally(() => {});
  }, [dispatch]);

  const prevType = get(history, 'location.state.type', 'SYMBOL');
  const prevSubType = get(
    history,
    'location.state.subType',
    'INDIVIDUAL_STATISTICAL_REPORT',
  );
  const prevPage = get(history, 'location.state.prevPage', '');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchType, setSearchType] = useState(prevType);
  const [searchSubType, setSearchSubType] = useState(prevSubType);
  const [fetchData, setFetchData] = useState<Date>(new Date());
  const userGroups = get(loggedInUser, 'groups', []);
  useEffect(() => {
    setIsLoading(true);
    const promises = [getRecentAndSavedSearches()];
    Promise.all(promises)
      .then((res) => {
        const { recentSearches, savedSearches } = res[0];
        dispatch(setRecentSearches(recentSearches || []));
        dispatch(setSavedSearches(savedSearches || []));
      })
      .catch(errorHandler)
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, fetchData]);

  const searchReportOptions = (returnAll: boolean) => {
    const reports = [
      {
        label: 'Symbols',
        value: 'SYMBOL',
        children: [
          {
            label: 'Individual Statistical Report',
            value: 'INDIVIDUAL_STATISTICAL_REPORT',
            hidden: false,
          },
          {
            label: 'Trending Stocks Report',
            value: 'TRENDING_STOCK',
            hidden: false,
          },
          {
            label: 'Price History',
            value: 'PRICE_HISTORY',
            hidden: false,
          },

          {
            label: 'Compare Symbols',
            value: 'COMPARE_SYMBOLS',
            hidden: false,
          },
          {
            label: 'Verify Symbols VS Institutions',
            value: 'VERIFY_SYMBOLS_VS_INSTITUTIONS',
            hidden: false,
          },
          {
            label: 'Moving Averages',
            value: 'MOVING_AVERAGES',
            hidden: false,
          },
          {
            label: 'TS Weekly Gems',
            value: 'TS_WEEKLY_REPORT',
            hidden: false,
          },
          {
            label: 'Daily Uptrend',
            value: 'UPTREND',
            hidden: false,
            children: [
              {
                label: 'Price',
                value: 'DOW_DAILY_PRICE_UPTREND',
                hidden: false,
              },
              {
                label: 'Price & Volume',
                value: 'DOW_DAILY_PRICE_VOLUME_UPTREND',
                hidden: false,
              },
            ],
          },
          {
            label: 'Daily Downtrend',
            value: 'DOWNTREND',
            hidden: false,
            children: [
              {
                label: 'Price',
                value: 'DOW_DAILY_PRICE_DOWNTREND',
                hidden: false,
              },

              {
                label: 'Price & Volume',
                value: 'DOW_DAILY_PRICE_VOLUME_DOWNTREND',
                hidden: false,
              },
            ],
          },
          {
            label: 'Weekly Uptrend',
            value: 'DOW_THEORY_UPTREND',
            hidden: false,
          },
          {
            label: 'Weekly Downtrend',
            value: 'DOW_THEORY_DOWNTREND',
            hidden: false,
          },

          {
            label: 'All Symbols',
            value: 'ALL_SYMBOLS',
            hidden: false,
          },
        ],
      },
      {
        label: 'Institutions',
        value: 'INSTITUTION',
        children: [
          {
            label: 'Individual Institution Report',
            value: 'INDIVIDUAL_INSTITUTION_REPORT',
            hidden: false,
          },
          {
            label: 'Quarterly Top 25 Buys & Sells',
            value: 'TOP_TWENTY_FIVE_SYMBOLS',
            hidden: false,
          },
          {
            label: 'Institutional Ranking Report',
            value: 'INSTITUTION_PERFORMANCE_REPORT',
            hidden: false,
          },
          {
            label: 'Compare Institutions',
            value: 'COMPARE_INSTITUTIONS',
            hidden: false,
          },
          {
            label: 'Verify Institutions VS Symbols',
            value: 'VERIFY_INSTITUTIONS_VS_SYMBOLS',
            hidden: false,
          },
          {
            label: 'All Institutions Counts By Symbols',
            value: 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS',
            hidden: false,
          },
          {
            label: 'Institution Sector Allocation Report',
            value: 'INSTITUTION_ALLOCATION_REPORT',
            hidden: false,
          },
          {
            label: 'Increased',
            value: 'INCREASED',
            hidden: false,
          },
          {
            label: 'No Change',
            value: 'NO_CHANGE',
            hidden: false,
          },
          {
            label: 'Decreased',
            value: 'DECREASED',
            hidden: false,
          },
          {
            label: 'Newly Bought',
            value: 'NEWLY_BOUGHT',
            hidden: false,
          },
          {
            label: 'Sold Off',
            value: 'SOLD_OFF',
            hidden: false,
          },
        ],
      },
      {
        label: 'Earnings Calendar',
        value: 'EARNINGS_CALENDAR',
        hidden: false,
        children: [],
      },
      {
        label: 'Thinksabio Diamonds',
        value: 'THINKSABIO_DIAMONDS',
        hidden: false,
        children: [
          {
            label: 'Quarterly %Shares Change Uptrend',
            value: 'THINKSABIO_DIAMONDS',
            hidden: false,
          },
          {
            label: 'Quarterly Price & Volume Gainers',
            value: 'THINKSABIO_DIAMONDS_PV',
            hidden: false,
          },
          {
            label: 'Quarterly CSAPV Gainers',
            value: 'THINKSABIO_DIAMONDS_PVCS',
            hidden: false,
          },
        ],
      },
      {
        label: 'Top 10 Hedge',
        value: 'TOP_10_HEDGE',
        children: [
          {
            label: 'Individual Institution Report',
            value: 'INDIVIDUAL_INSTITUTION_REPORT',
            hidden: false,
          },
          {
            label: 'Compare Institutions',
            value: 'COMPARE_INSTITUTIONS',
            hidden: false,
          },
          {
            label: 'Verify Institutions VS Symbols',
            value: 'VERIFY_INSTITUTIONS_VS_SYMBOLS',
            hidden: false,
          },
          {
            label: 'All Institutions Counts By Symbols',
            value: 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS',
            hidden: false,
          },
          {
            label: 'Increased',
            value: 'INCREASED',
            hidden: false,
          },
          {
            label: 'No Change',
            value: 'NO_CHANGE',
            hidden: false,
          },
          {
            label: 'Decreased',
            value: 'DECREASED',
            hidden: false,
          },
          {
            label: 'Newly Bought',
            value: 'NEWLY_BOUGHT',
            hidden: false,
          },
          {
            label: 'Sold Off',
            value: 'SOLD_OFF',
            hidden: false,
          },
        ],
      },
      {
        label: 'SC-13D/G',
        value: 'SEC_13_DG',
        children: [
          {
            label: 'Latest Report',
            value: 'DAILY_REPORT',
            hidden: false,
          },
          {
            label: 'By Symbol',
            value: 'SYMBOL_SUMMARY',
            hidden: false,
          },
          {
            label: 'All',
            value: 'DETAIL_SUMMARY_REPORT',
            hidden: false,
          },
          {
            label: 'Latest Top Buys and Sells',
            value: 'BY_APPROX_TXN_VAL',
            hidden: false,
          },
        ],
      },
      {
        label: 'Mutual Funds',
        value: 'MUTUAL_FUNDS',
        children: [
          {
            label: 'By Mutual Fund Symbol',
            value: 'BY_FUND_SYMBOL',
            hidden: false,
          },
          {
            label: 'By Symbol',
            value: 'SYMBOL_SUMMARY_MUTUAL_FUNDS',
            hidden: false,
          },
          {
            label: 'By Fund Name',
            value: 'BY_FUND_NAME',
            hidden: false,
          },
        ],
      },
      {
        label: 'Sector',
        value: 'SECTOR',
        children: [
          {
            label: 'Trending',
            // (
            //   <>
            //     Trending <span className={styles.newText}>New</span>
            //   </>
            // ),
            value: 'SECTOR_TRENDING',
            hidden: false,
          },
          {
            label: 'All Institution',
            value: 'ALL_INSTITUTIONS',
            hidden: false,
          },
          {
            label: 'Top 10 Hedge',
            value: 'TOP_10_HEDGE',
            hidden: false,
          },
          {
            label: 'Allocations',
            value: 'ALLOCATIONS',
            hidden: false,
          },
        ],
      },
      {
        label: 'SEC Insiders',
        value: 'SEC_INSIDERS',
        children: [
          {
            label: 'Latest',
            value: 'INSIDER_DAILY_REPORT',
            hidden: false,
          },
          {
            label: 'By Symbol',
            value: 'INSIDER_SYMBOL_SUMMARY',
          },
          {
            label: 'All',
            value: 'INSIDER_DETAIL_SUMMARY_REPORT',
            hidden: false,
          },
        ],
      },
      {
        label: 'Options',
        value: 'OPTION',
        children: [
          {
            label: 'All Institution',
            value: 'OPTION_ALL_INSTITUTION',
            hidden: false,
          },
          {
            label: 'By Institution',
            value: 'OPTION_BY_INSTITUTION',
            hidden: false,
          },
          {
            label: 'By Symbol',
            value: 'OPTION_BY_SYMBOL',
            hidden: false,
          },
        ],
      },
      {
        label: 'Price',
        value: 'PRICE',
        hidden: false,
        children: [],
      },
      {
        label: 'Mark Minervini Strategy',
        value: 'MARK_MINERVINI_STRATEGY',
        hidden: false,
        children: [],
      },
      {
        label: 'Build Your Own',
        value: 'BUILD_YOUR_OWN',
        hidden: false,
        children: [],
      },
      {
        label: 'Internal Reports',
        value: 'INTERNAL_REPORTS',
        hidden: true,
        children: [
          {
            label: 'All Cumulative',
            value: 'INSIDER_ALL_CUMULATIVE_REPORT',
            hidden: true,
          },
          {
            label: 'Top Gainers',
            value: 'TOP_GAINERS',
            hidden: true,
          },
          {
            label: 'Dow High Level',
            value: 'DOW_HIGH_LEVEL_REPORT',
            hidden: true,
          },
          {
            label: 'Dow Down Trend',
            value: 'DOW_DOWN_TREND_REPORT',
            hidden: true,
          },
          {
            label: 'Weekly Gains Report',
            value: 'WEEKLY_GAINS_REPORT',
            hidden: true,
          },
          {
            label: 'Telegram Options',
            value: 'TELEGRAM_OPTIONS',
            hidden: true,
          },
          {
            label: 'Weekly YTD',
            value: 'WEEKLY_YTD',
            hidden: true,
          },
        ],
      },
    ];
    return returnAll
      ? reports
      : reports
          .filter((r) => !r.hidden)
          .filter((r) => {
            if (r.children && r.children.length) {
              r.children = r.children.filter((i) => !i.hidden);
              return r;
            } else return r;
          });
  };

  const displayRender = (labels, selectedOptions) => {
    if (!selectedOptions.length) {
      selectedOptions = getDefaultOptions();
    }
    return labels.map((label, i) => {
      const option = selectedOptions[i];
      if (option) {
        if (i === labels.length - 1) {
          return <span key={option.value}>{label}</span>;
        }
        return <span key={option.value}>{label} / </span>;
      }
    });
  };

  const redirectToReportPage = async (
    type: string,
    subType: string,
    filters: {},
    searchSymbols: any,
    insName: any,
  ) => {
    const searchFilters = {
      type: type.toLowerCase(),
      subtype: subType.toLowerCase(),
    };
    const { ciks, symbols, criterion, sector, minPrice, maxPrice } = filters;
    if (subType === 'INDIVIDUAL_INSTITUTION_REPORT') {
      getIIRQuarters({
        context: 'INDIVIDUAL_INSTITUTION_REPORT',
        ciks: ciks,
      }).then((response) => {
        dispatch(setIIRQuarters(ciks[0], response));
        searchFilters.institutions = ciks;
        searchFilters.institutionsName = insName;
        history.push({
          pathname:
            `/search/report/` +
            queryString.stringify(searchFilters, {
              skipNull: true,
            }),
          state: { prevPage: 'search' },
        });
      });
    } else {
      switch (subType) {
        case 'INDIVIDUAL_STATISTICAL_REPORT':
        case 'PRICE_HISTORY':
        case 'COMPARE_SYMBOLS':
          searchFilters.symbols = searchSymbols;
          break;
        case 'VERIFY_SYMBOLS_VS_INSTITUTIONS':
          searchFilters.symbols = symbols;
          searchFilters.institutions = ciks;
          break;
        case 'MOVING_AVERAGES':
          searchFilters.symbols = symbols;
          // searchFilters.days = days;
          break;
        case 'DOW_THEORY_UPTREND':
        case 'TS_WEEKLY_REPORT':
        case 'DOW_THEORY_DOWNTREND':
        case 'DOW_DAILY_PRICE_UPTREND':
        case 'DOW_DAILY_PRICE_DOWNTREND':
        case 'ALL_SYMBOLS':
        case 'TOP_TWENTY_FIVE_SYMBOLS':
        case 'TRENDING_STOCK':
          break;
        // case 'INDIVIDUAL_INSTITUTION_REPORT':
        //   searchFilters.institutions = ciks;
        //   searchFilters.institutionsName = insName;
        //   break;
        case 'COMPARE_INSTITUTIONS':
          searchFilters.institutions = ciks;
          break;
        case 'VERIFY_INSTITUTIONS_VS_SYMBOLS':
          searchFilters.institutions = ciks;
          searchFilters.symbols = symbols;
          break;
        case 'ALL_INSTITUTIONS_COUNTS_BY_SYMBOLS':
          break;
        case 'THINKSABIO_DIAMONDS':
          break;
        case 'INCREASED':
        case 'NO_CHANGE':
        case 'DECREASED':
        case 'NEWLY_BOUGHT':
        case 'SOLD_OFF':
          searchFilters.criterion = criterion.toLowerCase();
          break;
        case 'ALL_INSTITUTIONS':
        case 'TOP_10_HEDGE':
          searchFilters.sector = sector;
          break;
        case 'ALLOCATIONS':
        case 'SECTOR_TRENDING':
          break;
        case 'DAILY_REPORT':
        case 'SYMBOL_SUMMARY':
        case 'DETAIL_SUMMARY_REPORT':
          break;
        default:
          break;
        case 'CRITERIA_MET':
        case 'EXIT_REPORT':
          // delete searchFilters.subtype;
          break;
      }
      switch (type) {
        case 'PRICE':
          delete searchFilters.subtype;
          searchFilters.minPrice = minPrice;
          searchFilters.maxPrice = maxPrice;
          break;
        default:
          break;
      }
      history.push({
        pathname:
          `/search/report/` +
          queryString.stringify(searchFilters, {
            skipNull: true,
          }),

        state: { prevPage: 'search' },
      });
    }
  };

  const changeSearchType = (value) => {
    const type = value[0];
    const subType = value[1];
    const subType2 = value[2];
    if (type === 'TOP_GAINERS') {
      // const dateRange = getTimeRange(timeframe);
      const filters = {
        type: type.toLowerCase(),
      };
      history.push({
        pathname:
          `/search/report/` +
          queryString.stringify(filters, {
            skipNull: true,
          }),
      });
    } else {
      setSearchType(type);
    }
    if (subType2) {
      setSearchSubType(subType2);
    } else if (subType) {
      setSearchSubType(subType);
    }
  };

  const handleDelete = (payload: object, type: string, cb) => {
    const promises = [];
    if (type === 'recent') {
      promises.push(deletedRecentSearch(payload));
    }
    if (type === 'saved') {
      promises.push(deletedSavedSearch(payload));
    }
    Promise.all(promises)
      .then((res) => {
        if (type === 'recent') {
          message.success('Search deleted successfully');
        }
        if (type === 'saved') {
          message.success('Report deleted successfully');
        }
        setFetchData(new Date());
        cb();
      })
      .catch(errorHandler)
      .finally(() => {});
  };
  const getDefaultOptions = () => {
    const values = [];
    if (searchType) {
      let value1 = searchReportOptions(
        userGroups.includes('new_reports_testing_team'),
      ).find((option) => option.value === searchType);
      if (value1) {
        values.push(value1);
      }

      if (value1.children) {
        let value2 = value1.children.find(
          (option) => option.value === searchSubType,
        );
        if (value2) {
          values.push(value2);
        }
      }
    }
    return values;
  };
  const getDefaultValue = () => {
    const values = [];
    if (searchType) {
      let value1 = searchReportOptions(
        userGroups.includes('new_reports_testing_team'),
      ).find((option) => option.value === searchType);
      if (value1) {
        values.push(value1.label);
      }

      if (value1.children) {
        let value2 = value1.children.find(
          (option) => option.value === searchSubType,
        );
        if (value2) {
          values.push(value2.label);
        }
      }
    }
    return values;
  };
  return (
    <div className={styles.vNewSearchPageBox}>
      <div className={styles.vNewSearchPageBlock1}>
        <div className={styles.VsearchNew}>
          <Card
            className={styles.cardNew}
            style={{ margin: 20 }}
            bodyStyle={{
              padding: 0,
              //maxHeight: 400,
              overflow: 'auto',
              width: '100%',
            }}
            headStyle={{ fontSize: 18, padding: 0 }}
          >
            <Meta
              title={
                <h1
                  className={styles.cardTitle}
                  style={{
                    textAlign: 'center',
                    color: '#FFF',
                    marginBottom: '40px',
                    fontSize: '22px',
                  }}
                >
                  Choose Type
                </h1>
              }
            />
            <Cascader
              options={searchReportOptions(
                userGroups.includes('new_reports_testing_team'),
              )}
              defaultValue={getDefaultValue()}
              // defaultValue={['Symbols', 'Individual Statistical Report']}
              style={{
                width: '100%',
                marginBottom: 20,
                fontSize: '16px',
                height: '50px',
              }}
              onChange={(e) => changeSearchType(e)}
              allowClear={false}
              displayRender={displayRender}
              expandTrigger="hover"
              placeholder="Please select"
              popupClassName={styles.cascaderPopup}
              size="large"
            />

            {searchType === 'SYMBOL' && (
              <SymbolSearch
                reportType={searchType}
                reportSubType={searchSubType}
                history={history}
                prevPage={prevPage}
              />
            )}
            {searchType === 'INSTITUTION' && (
              <InstitutionSearch
                reportType={searchType}
                reportSubType={searchSubType}
                history={history}
                prevPage={prevPage}
              />
            )}
            {searchType === 'TOP_10_HEDGE' && (
              <Top10HedgeSearch
                reportType={searchType}
                reportSubType={searchSubType}
                history={history}
                prevPage={prevPage}
              />
            )}
            {searchType === 'SEC_13_DG' && (
              <SEC13DGSearch
                reportType={searchType}
                reportSubType={searchSubType}
                history={history}
                prevPage={prevPage}
              />
            )}
            {searchType === 'MUTUAL_FUNDS' && (
              <MutualFundsSearch
                reportType={searchType}
                reportSubType={searchSubType}
                history={history}
                prevPage={prevPage}
              />
            )}
            {searchType === 'SECTOR' && (
              <SectorSearch
                reportType={searchType}
                reportSubType={searchSubType}
                history={history}
                prevPage={prevPage}
              />
            )}
            {searchType === 'PRICE' && (
              <PriceSearch
                reportType={searchType}
                reportSubType={searchSubType}
                history={history}
                prevPage={prevPage}
              />
            )}
            {searchType === 'SEC_INSIDERS' && (
              <SecInsiderSearch
                reportType={searchType}
                reportSubType={searchSubType}
                history={history}
                prevPage={prevPage}
              />
            )}
            {searchType === 'OPTION' && (
              <OptionSearch
                reportType={searchType}
                reportSubType={searchSubType}
                history={history}
                prevPage={prevPage}
              />
            )}
            {searchType === 'BUILD_YOUR_OWN' && (
              <BuildYourOwnSearch
                reportType={searchType}
                reportSubType={searchSubType}
                history={history}
                prevPage={prevPage}
              />
            )}
            {searchType === 'INTERNAL_REPORTS' && (
              <InternalReportSearch
                reportType={searchType}
                reportSubType={searchSubType}
                history={history}
                prevPage={prevPage}
              />
            )}
            {searchType === 'MARK_MINERVINI_STRATEGY' && (
              <MarkMinerviniStrategySearch
                reportType={searchType}
                reportSubType={searchSubType}
                history={history}
                prevPage={prevPage}
              />
            )}
            {searchType === 'EARNINGS_CALENDAR' && (
              <EarningsCalendarSearch
                reportType={searchType}
                reportSubType={searchSubType}
                history={history}
                prevPage={prevPage}
              />
            )}
            {searchType === 'THINKSABIO_DIAMONDS' && (
              <ThinksabioDiamondsSearch
                reportType={searchType}
                reportSubType={searchSubType}
                history={history}
                prevPage={prevPage}
              />
            )}
          </Card>
        </div>
        <div className={styles.vSearchBtnNew1} id="mySavedSearches">
          <Tabs defaultActiveKey="2" id="vTabsSearchNew" centered>
            <TabPane
              tab={
                <>
                  <HeartOutlined />
                  <span>
                    My Saved <br />
                    searches
                  </span>
                </>
              }
              key="1"
            >
              <div className={styles.vBtnBlocksNew}>
                <SavedSearches
                  data={savedSearches}
                  isLoading={isLoading}
                  handleDelete={handleDelete}
                  redirectToReportPage={redirectToReportPage}
                  setFetchData={setFetchData}
                />
              </div>
            </TabPane>
            <TabPane
              tab={
                <>
                  <RiseOutlined />
                  <span>
                    Recent <br /> searches
                  </span>
                </>
              }
              key="2"
            >
              <div className={styles.vBtnBlocksNew}>
                <RecentSearches
                  data={recentSearches}
                  isLoading={isLoading}
                  handleDelete={handleDelete}
                  redirectToReportPage={redirectToReportPage}
                  setFetchData={setFetchData}
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Search;

// import { get } from 'lodash';
// import queryString from 'query-string';
import {
  colRegexMatch,
  cntRegex,
  shrsRegex,
  marketCapOptions,
  tsConvictionOptions,
} from 'utils';

export const selectObjectFields = (code, sectorOptions) => {
  let fields = [
    {
      name: 'sector',
      placeholder: 'Sector',
      data: sectorOptions,
    },
  ];
  return fields;
};

export const inputFieldsPositive = () => {
  let fields = [
    // {
    //   name: 'minPrice',
    //   placeholder: 'Min Price',
    //   label: 'Min Price',
    //   allowDecimal: true,
    //   visible: true,
    // },
    // {
    //   name: 'maxPrice',
    //   placeholder: 'Max Price',
    //   label: 'Max Price',
    //   allowDecimal: true,
    //   visible: true,
    // },
    {
      name: 'minInstCount',
      placeholder: 'Min Inst count',
      label: 'Min Inst count',
      allowDecimal: false,
      visible: true,
    },
  ];
  return fields;
};

export const inputFields = () => {
  let fields = [
    {
      name: 'minYTD',
      placeholder: 'Min YTD',
      label: 'Min YTD',
      allowDecimal: true,
      visible: true,
    },
    {
      name: 'min1Yr',
      placeholder: 'Min 1Yr %',
      label: 'Min 1Yr %',
      allowDecimal: true,
      visible: true,
    },
    {
      name: 'min5Yr',
      placeholder: 'Min 5Yr %',
      label: 'Min 5Yr %',
      allowDecimal: true,
      visible: true,
    },
  ];
  return fields;
};

export const columns = (type: string) => {
  const list = [
    {
      value: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      sort: true,
    },
    {
      value: 'Name',
      dataIndex: 'name',
      key: 'name',
      sort: true,
    },
    {
      value: 'Sector',
      dataIndex: 'sector',
      key: 'sector',
      sort: true,
    },
    {
      value: 'Posted Date',
      dataIndex: 'date',
      key: 'date',
      sort: true,
    },
    // {
    //   value: 'Price on Recommended Date',
    //   dataIndex: 'priceOnRecDate',
    //   key: 'priceOnRecDate',
    //   sort: true,
    // },
    {
      value: 'Price on Posted Date',
      dataIndex: 'price_on_entry',
      key: 'price_on_entry',
      sort: true,
    },
    {
      value: 'Highest Price Since Posted',
      dataIndex: 'highestPriceSinceReco',
      key: 'highestPriceSinceReco',
      sort: true,
    },
    {
      value: 'Highest Growth Since Posted',
      dataIndex: 'highestGrowthSinceRecommended',
      key: 'highestGrowthSinceRecommended',
      sort: true,
    },
    {
      value: 'Highest Price Date',
      dataIndex: 'highestPriceDate',
      key: 'highestPriceDate',
      sort: true,
    },

    {
      value: `Current Price`,
      dataIndex: 'lastSale',
      key: 'lastSale',
      sort: true,
    },
    {
      value: 'YTD',
      key: 'ytd',
      dataIndex: 'ytd',
      sort: true,
    },
    {
      value: '1 year',
      key: 'oneYrGrowth',
      dataIndex: 'oneYrGrowth',
      sort: true,
    },
    {
      value: '5 year',
      key: 'fiveYrGrowth',
      dataIndex: 'fiveYrGrowth',
      sort: true,
    },

    {
      value: 'Why ?',
      key: 'detail',
      sort: false,
    },
  ];

  const list1 = [
    {
      value: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      sort: true,
    },
    {
      value: 'Name',
      dataIndex: 'name',
      key: 'name',
      sort: true,
    },
    {
      value: 'Market cap',
      dataIndex: 'mCap',
      key: 'mCap',
      sort: true,
    },
    {
      value: 'Live Cash',
      dataIndex: 'live_cash',
      key: 'live_cash',
      sort: true,
    },
    {
      value: 'Posted Date',
      dataIndex: 'date',
      key: 'date',
      sort: true,
    },
    {
      value: 'Price on Posted Date',
      dataIndex: 'price_on_entry',
      key: 'price_on_entry',
      sort: true,
    },
    {
      value: 'Highest Price Since Posted',
      dataIndex: 'highestPriceSinceReco',
      key: 'highestPriceSinceReco',
      sort: true,
    },
    {
      value: 'Highest Growth Since Posted',
      dataIndex: 'highestGrowthSinceRecommended',
      key: 'highestGrowthSinceRecommended',
      sort: true,
    },
    {
      value: 'Highest Price Date',
      dataIndex: 'highestPriceDate',
      key: 'highestPriceDate',
      sort: true,
    },

    {
      value: `Current Price`,
      dataIndex: 'lastSale',
      key: 'lastSale',
      sort: true,
    },
    {
      value: `52wk High`,
      dataIndex: 'c52WkHigh',
      key: 'c52WkHigh',
      sort: true,
    },
    {
      value: `52wk Low`,
      dataIndex: 'c52WkLow',
      key: 'c52WkLow',
      sort: true,
    },
    {
      value: `SC 13G count`,
      dataIndex: 'sec13dCount',
      key: 'sec13dCount',
      sort: true,
    },
    {
      value: colRegexMatch('lastQtr2Count', cntRegex, ' Inst#'),
      dataIndex: 'lastQtr2Count',
      key: 'lastQtr2Count',
      sort: true,
    },
    {
      value: colRegexMatch('lastQtr1Count', cntRegex, ' Inst#'),
      dataIndex: 'lastQtr1Count',
      key: 'lastQtr1Count',
      sort: true,
    },
    {
      value: 'Status',
      dataIndex: 'changeCountsStatus',
      key: 'changeCountsStatus',
      sort: false,
    },
    {
      value: colRegexMatch('lastQtr2Shares', shrsRegex, ' SHRS'),
      dataIndex: 'lastQtr2Shares',
      key: 'lastQtr2Shares',
      sort: true,
    },
    {
      value: colRegexMatch('lastQtr1Shares', shrsRegex, ' SHRS'),
      dataIndex: 'lastQtr1Shares',
      key: 'lastQtr1Shares',
      sort: true,
    },
    {
      value: 'Status',
      dataIndex: 'changeShareStatus',
      key: 'changeShareStatus',
      sort: false,
    },
    {
      value: 'YTD',
      key: 'ytd',
      dataIndex: 'ytd',
      sort: true,
    },
    {
      value: '1 year',
      key: 'oneYrGrowth',
      dataIndex: 'oneYrGrowth',
      sort: true,
    },
    {
      value: '5 year',
      key: 'fiveYrGrowth',
      dataIndex: 'fiveYrGrowth',
      sort: true,
    },
    {
      value: `TS conviction`,
      dataIndex: 'ts_conviction',
      key: 'ts_conviction',
      sort: true,
    },

    {
      value: 'Why ?',
      key: 'detail',
      sort: false,
    },
  ];

  const list2 = [
    {
      value: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      sort: true,
    },
    {
      value: 'Name',
      dataIndex: 'name',
      key: 'name',
      sort: true,
    },

    {
      value: 'Market cap',
      dataIndex: 'mCap',
      key: 'mCap',
      sort: true,
    },
    {
      value: 'Live Cash',
      dataIndex: 'live_cash',
      key: 'live_cash',
      sort: true,
    },
    // {
    //   value: 'Event Date',
    //   dataIndex: 'event_date',
    //   key: 'event_date',
    //   sort: true,
    // },
    {
      value: 'Upcoming Catalyst',
      dataIndex: 'upcoming_catalyst',
      key: 'upcoming_catalyst',
      sort: true,
    },
    {
      value: `Current Price`,
      dataIndex: 'lastSale',
      key: 'lastSale',
      sort: true,
    },
    {
      value: `52wk High`,
      dataIndex: 'c52WkHigh',
      key: 'c52WkHigh',
      sort: true,
    },
    {
      value: `52wk Low`,
      dataIndex: 'c52WkLow',
      key: 'c52WkLow',
      sort: true,
    },
    {
      value: `SC 13G count`,
      dataIndex: 'sec13dCount',
      key: 'sec13dCount',
      sort: true,
    },
    {
      value: colRegexMatch('lastQtr2Count', cntRegex, ' Inst#'),
      dataIndex: 'lastQtr2Count',
      key: 'lastQtr2Count',
      sort: true,
    },
    {
      value: colRegexMatch('lastQtr1Count', cntRegex, ' Inst#'),
      dataIndex: 'lastQtr1Count',
      key: 'lastQtr1Count',
      sort: true,
    },
    {
      value: 'Status',
      dataIndex: 'changeCountsStatus',
      key: 'changeCountsStatus',
      sort: false,
    },
    {
      value: colRegexMatch('lastQtr2Shares', shrsRegex, ' SHRS'),
      dataIndex: 'lastQtr2Shares',
      key: 'lastQtr2Shares',
      sort: true,
    },
    {
      value: colRegexMatch('lastQtr1Shares', shrsRegex, ' SHRS'),
      dataIndex: 'lastQtr1Shares',
      key: 'lastQtr1Shares',
      sort: true,
    },
    {
      value: 'Status',
      dataIndex: 'changeShareStatus',
      key: 'changeShareStatus',
      sort: false,
    },
    {
      value: 'YTD',
      key: 'ytd',
      dataIndex: 'ytd',
      sort: true,
    },
    {
      value: '1 year',
      key: 'oneYrGrowth',
      dataIndex: 'oneYrGrowth',
      sort: true,
    },
    {
      value: '5 year',
      key: 'fiveYrGrowth',
      dataIndex: 'fiveYrGrowth',
      sort: true,
    },
    {
      value: `TS conviction`,
      dataIndex: 'ts_conviction',
      key: 'ts_conviction',
      sort: true,
    },

    {
      value: 'Why ?',
      key: 'detail',
      sort: false,
    },
  ];

  if (type === 'monthly') {
    list.unshift({
      value: 'Rank',
      key: 'rank',
      dataIndex: 'rank',
      sort: true,
    });
  }
  if (type === 'BIO') return list1;
  if (type === 'CATALYST') return list2;
  return list;
};

export const pickDetailColumns = [
  {
    value: 'lastQtr1Count',
    dataIndex: 'lastQtr1Count',
    key: 'lastQtr1Count',
  },
  {
    value: 'lastQtr2Count',
    dataIndex: 'lastQtr2Count',
    key: 'lastQtr2Count',
  },
  {
    value: 'lastQtr3Count',
    dataIndex: 'lastQtr3Count',
    key: 'lastQtr3Count',
  },
  {
    value: 'lastQtr4Count',
    dataIndex: 'lastQtr4Count',
    key: 'lastQtr4Count',
  },
  {
    value: 'lastQtr5Count',
    dataIndex: 'lastQtr5Count',
    key: 'lastQtr5Count',
  },
  {
    value: 'lastQtr6Count',
    dataIndex: 'lastQtr6Count',
    key: 'lastQtr6Count',
  },
];

export const listItems = [
  'lastQtr6Count',
  'lastQtr5Count',
  'lastQtr4Count',
  'lastQtr3Count',
  'lastQtr2Count',
  'lastQtr1Count',
  // 'lastQtr7Count',
  // 'lastQtr8Count',
];

export const filterFields = (type) => {
  let list = [
    {
      name: 'symbols',
      placeholder: 'Enter Symbols',
      label: 'Enter Symbols',
      visible: true,
      type: 'symbols',
      size: 24,
    },

    {
      name: 'sector',
      placeholder: 'Sector',
      size: 12,
      visible: !(type === 'BIO' || type === 'CATALYST'),
      type: 'multiSector',
      multiple: true,
    },
    {
      name: 'excludeMultiSector',
      placeholder: 'Exclude Sector',
      size: 12,
      visible: !(type === 'BIO' || type === 'CATALYST'),
      type: 'excludeMultiSector',
      multiple: true,
    },

    {
      name: 'minPrice',
      placeholder: 'Min Price',
      label: 'Min Price',
      allowDecimal: true,
      visible: true,
      type: 'minPrice',
      size: 12,
    },

    {
      name: 'maxPrice',
      placeholder: 'Max Price',
      label: 'Max Price',
      allowDecimal: true,
      visible: true,
      type: 'maxPrice',
      size: 12,
    },
    {
      name: 'minYTD',
      placeholder: 'Min YTD',
      label: 'Min YTD',
      allowDecimal: true,
      visible: true,
      type: 'inputField',
      size: 12,
    },
    {
      name: 'min1Yr',
      placeholder: 'Min 1Yr %',
      label: 'Min 1Yr %',
      allowDecimal: true,
      visible: true,
      type: 'inputField',
      size: 12,
    },
    {
      name: 'min5Yr',
      placeholder: 'Min 5Yr %',
      label: 'Min 5Yr %',
      allowDecimal: true,
      visible: true,
      type: 'inputField',
      size: 12,
    },
    {
      name: 'multiMarketCap',
      placeholder: 'Market Cap',
      data: marketCapOptions,
      size: 12,
      type: 'selectObjectField',
      multiple: true,
      visible: type === 'BIO' || type === 'CATALYST',
    },
    {
      name: 'minInstCount',
      placeholder: 'Min Inst count',
      label: 'Min Inst count',
      allowDecimal: false,
      visible: type === 'BIO' || type === 'CATALYST',
      size: 12,
      type: 'minInstCount',
    },
    {
      name: 'maxInstCount',
      placeholder: 'Max Inst count',
      label: 'Max Inst count',
      allowDecimal: false,
      visible: type === 'BIO' || type === 'CATALYST',
      size: 12,
      type: 'maxInstCount',
    },
    {
      name: 'minInstShares',
      placeholder: 'Min Inst Shares',
      label: 'Min Inst Shares',
      allowDecimal: false,
      visible: type === 'BIO' || type === 'CATALYST',
      size: 12,
      type: 'minInstShares',
    },
    {
      name: 'maxInstShares',
      placeholder: 'Max Inst Shares',
      label: 'Max Inst Shares',
      allowDecimal: false,
      visible: type === 'BIO' || type === 'CATALYST',
      size: 12,
      type: 'maxInstShares',
    },
    {
      name: 'minSec13dCount',
      placeholder: 'Min SEC 13D/G Count',
      label: 'Min SEC 13D/G Count',
      allowDecimal: false,
      visible: type === 'BIO' || type === 'CATALYST',
      size: 12,
      type: 'inputFieldPositive',
    },
    {
      name: 'tsConviction',
      placeholder: 'TS Conviction',
      data: tsConvictionOptions,
      size: 12,
      type: 'selectObjectField',
      multiple: false,
      visible: type === 'BIO' || type === 'CATALYST',
    },
    {
      name: 'minLiveCash',
      placeholder: 'Min Live Cash',
      label: 'Min Live Cash',
      allowDecimal: false,
      visible: type === 'BIO' || type === 'CATALYST',
      size: 12,
      type: 'minLiveCash',
    },
    {
      name: 'maxLiveCash',
      placeholder: 'Max Live Cash',
      label: 'Max Live Cash',
      allowDecimal: false,
      visible: type === 'BIO' || type === 'CATALYST',
      size: 12,
      type: 'maxLiveCash',
    },
    // {
    //   name: 'eventDate',
    //   placeholder: 'Event date',
    //   label: 'Event date',
    //   visible: type === 'CATALYST',
    //   size: 12,
    //   type: 'eventDate',
    // },
    // {
    //   name: 'minInstCount',
    //   placeholder: 'Min Inst count',
    //   label: 'Min Inst count',
    //   allowDecimal: false,
    //   visible: true,
    //   type: 'minInstCount',
    //   size: 12,
    // },
    // {
    //   name: 'maxInstCount',
    //   placeholder: 'Max Inst count',
    //   label: 'Max Inst count',
    //   allowDecimal: false,
    //   visible: true,
    //   type: 'maxInstCount',
    //   size: 12,
    // },
  ];
  if (type === 'symbol') {
    return list.filter((item) => item.name !== 'symbols');
  }
  return list.filter(({ visible }) => visible);
};

// export const getSOrtValuesFromUrl = (picksQueryData) => {
//   const sortValues = get(picksQueryData, 'sort', []);
//   if (typeof sortValues === 'string') {
//     return [queryString.parse(sortValues)];
//   } else if (Array.isArray(sortValues)) {
//     return sortValues.map((val) => val);
//   } else return [];
// };

// export const getSymbolsValuesFromUrl = (picksQueryData) => {
//   const symbolsValues = get(picksQueryData, 'symbols', []);
//   if (typeof symbolsValues === 'string') {
//     return [symbolsValues];
//   } else if (Array.isArray(symbolsValues)) {
//     return symbolsValues.map((val) => val);
//   } else return [];
// };

export type OurPickType = {
  date: string;
  fiveYrGrowth: number;
  growthSinceRecommended: number;
  id: number;
  name: string;
  oneYrGrowth: number;
  rank: null;
  recoType: string;
  symbol: string;
  type: string;
};
export interface FilterType {
  symbols?: string;
  minPrice?: string;
  maxPrice?: string;
  sector?: string;
}
